import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['input', 'content']

  connect() {
  }

  focus(event) {
    // Focus the search input when "/" is pressed, but only if no form input is currently focused.
    var activeElement = document.activeElement;
    var inputs = ['input', 'select', 'button', 'textarea', 'trix-editor'];

    if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
        return false;
    }
    if (event.type == "keyup" && event.keyCode == 191 && !this.element.classList.contains("open") ) {
      this.inputTarget.focus();
    }
  }

  open() {
    this.contentTarget.classList.remove("hidden");
    this.element.classList.add("open");
    document.querySelector("body").classList.add("search-open");
  }

  hide(event) {
    // Hide the search dropdown when clicked outside or if ESC key is pressed.
    if (
      this.element.contains(event.target) === false ||
      (event.type == "keydown" && event.keyCode == 27)
      ) {
      this.contentTarget.classList.add("hidden");
      this.element.classList.remove("open");
      document.querySelector("body").classList.remove("search-open");
      this.inputTarget.value = '';
    }
  }
  
  navigate(event) {
    if (this.element.classList.contains("open")) {
      var search_results = Array.from(this.element.querySelectorAll("a.search-result"));
      var focused_result = this.element.querySelector("a.search-result:focus");
      var i = 0;
      
      if (event.keyCode == 38) { // UP key is pressed
        event.preventDefault();
        if (focused_result) {
          i = search_results.indexOf(focused_result);
          if (i == 0) {
            this.inputTarget.focus();
          } else {
            i = i - 1;
            search_results[i].focus();
          }
        } else {
          search_results[i].focus();
        }
      }
      if (event.keyCode == 40) { // DOWN key is pressed
        event.preventDefault();
        if (focused_result) {
          i = search_results.indexOf(focused_result);
          if (i == search_results.length - 1) {
            this.inputTarget.focus();
          } else {
            i = i + 1;
            search_results[i].focus();
          }
        } else {
          search_results[i].focus();
        }
      }
    }
  }

  fetchResults() {
    if (this.hasInputTarget && event.keyCode != 40 && event.keyCode != 38) {
      this.contentTarget.innerHTML = this.data.get("loader");

      var inputValue = this.inputTarget.value;

      if (typeof (inputValue) != 'undefined' && inputValue != null) {
        var params = new URLSearchParams({ q: inputValue })

        fetch(`/${accountId}/search?${params}`, {
          method: "GET",
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          return response.json();
        }).then((json) => {
          if(this.hasContentTarget){
            if(json.search_results_found === true)
              this.contentTarget.innerHTML = json.search_results;
            else {
              this.contentTarget.innerHTML = '<span class="no-results">No results</span>';
            }
          }
        }).catch((error) => {
          console.log(error)
          error.json().then(errorMessage => {
            console.log(errorMessage)
          })
        })
      }
    }
  }
  
}
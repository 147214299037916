// This is used both for the field_options, client_attr_template_opts, step_block options & task_block options

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['optionField'];
  
  addFieldTemplateOption(event) {
    event.preventDefault();

    // Get the value of data-fields, which contains the markup that will be inserted
    var field = event.target.dataset.fields;

    var add_option_link = this.optionFieldTarget.querySelector(".define-field-template-options .add-a-field");

    // insert the markup (stored in var field) into dom, before the .add-fields link
    add_option_link.insertAdjacentHTML("beforebegin", field);

    // Get the div.two-fields that was previously added just before this one
    var nodes = this.optionFieldTarget.querySelectorAll(".define-field-template-options .field-option");
    var secondToLastNode = nodes[nodes.length - 2];

    // Get the value of data-option-id and convert it to integer
    var secondToLastOptionId = secondToLastNode.dataset.optionId;
    var secondToLastOptionIdInt = parseInt(secondToLastOptionId, 10);

    // Get the div that was just added
    var lastNode = nodes[nodes.length - 1];
    
    // set data-option-id to the previous value + 1
    var newOptionId = secondToLastOptionIdInt+1
    lastNode.dataset.optionId = newOptionId

    // Find all of the input tags inside of lastNode
    var input_tags = lastNode.querySelectorAll("input");

    for (let input_tag of input_tags) {
      // update the id of each input tag with the number incremented by 1
      if (input_tag.id) {
        var r = /\d+/;
        var new_id = input_tag.id.replace(r, newOptionId);
        input_tag.setAttribute('id', new_id);
      }
      // update the name of each input tag with the number incremented by 1
      if (input_tag.name) {
        var r = /(.*)\[\d+\]/;
        var new_name = input_tag.name.replace(r, '$1[' + newOptionId + ']');
        input_tag.setAttribute('name', new_name);
      }
    }

    // Focus the newly added option text input
    lastNode.querySelector("input[type='text']").focus();

  }

  oneDefaultSelected(event) {
    if (event.target.checked) {
      var options_wrap = event.target.closest(".define-field-template-options");
      var checkboxes = options_wrap.querySelectorAll(".selected-by-default input");
      for (let checkbox of checkboxes) {
        if (event.target !== checkbox) {
          checkbox.checked = false;
        }
      }
    }
  }

  markForDeletion(event) {
    var option_wrap = event.target.closest(".field-option");
    option_wrap.querySelector("input.deletion-field").value = true;
    option_wrap.classList.add("hidden");
  }

}
import { Controller } from 'stimulus'

export default class extends Controller {

  click(event) {
    // If the viewport is mobile size (<768px) then disable the link 
    // so that clicking the logo simply opens the app nav dropdown instead of navigating somewhere else

    var app_nav_links = this.element.closest('.app-nav').querySelector('.dropdown-content');

    // Define our viewportWidth variable
    var viewportWidth;

    // Set/update the viewportWidth value
    var setViewportWidth = function () {
      viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    }

    // Log the viewport width into the console
    var logWidth = function () {
      if (viewportWidth < 768) {
        if (app_nav_links) { // only prevent clicking if there is an app nav dropdown to display on mobile. 
          event.preventDefault();
        }
      } 
    }

    // Set our initial width
    setViewportWidth();
    logWidth();

    // On resize events, recalculate
    window.addEventListener('resize', function () {
      setViewportWidth();
      logWidth();
    }, false);

  } 

}
document.addEventListener("turbolinks:load", function() {
  let element = document.querySelector("#card-element")
  if (element == null) { return }

  var stripe_publishable_key = document.querySelector("meta[name='stripe-publishable-key']").content;
  var stripe = Stripe(stripe_publishable_key);
  var elements = stripe.elements();

  // Custom styling can be passed to options when creating an Element.
  var style = {
    base: {
      // Add your base input styles here. For example:
      fontSize: '16px',
      color: "#575F67"
    },
    invalid: {
      color: '#a4200d',
      iconColor: '#a4200d'
    }
  };

  // Create an instance of the card Element.
  var card = elements.create('card', {style: style});

  // Add an instance of the card Element into the `card-element` <div>.
  card.mount('#card-element');

  card.addEventListener('change', function(event) {
    var displayError = document.getElementById('card-errors');
    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = '';
    }
  });

  // Create a token or display an error when the form is submitted.
  var form = document.getElementById('payment-form');
  form.addEventListener('submit', function(event) {
    event.preventDefault();

    stripe.createToken(card).then(function(result) {
      if (result.error) {
        // Inform the customer that there was an error.
        var errorElement = document.getElementById('card-errors');
        errorElement.textContent = result.error.message;
      } else {
        // Send the token to your server.
        stripeTokenHandler(result.token);
      }
    });
  });

  // Update payment method
  // Create a source or display an error when the form is submitted.
  var payment_method_form = document.getElementById('payment-form');
  payment_method_form.addEventListener('submit', function(event) {
    event.preventDefault();

    stripe.createSource(card).then(function(result) {
      if (result.error) {
        // Inform the user if there was an error
        var errorElement = document.getElementById('card-errors');
        errorElement.textContent = result.error.message;
      } else {
        // Send the source to your server
        stripeSourceHandler(result.source);
      }
    });
  });

})

// ===============
// The following 2 functions are for the Create Subscription form:

function stripeTokenHandler(token) {
  // Insert the token ID into the form so it gets submitted to the server
  var form = document.getElementById('payment-form');
  var hiddenInput = document.createElement('input');
  hiddenInput.setAttribute('type', 'hidden');
  hiddenInput.setAttribute('name', 'stripeToken');
  hiddenInput.setAttribute('value', token.id);
  form.appendChild(hiddenInput);

  ["brand", "exp_month", "exp_year", "last4"].forEach(function(field) {
    addFieldToPurchaseForm(form, token, field);
  });

  // Submit the form
  // form.submit();
}

function addFieldToPurchaseForm(form, token, field) {
  var hiddenInput = document.createElement('input');
  hiddenInput.setAttribute('type', 'hidden');
  hiddenInput.setAttribute('name', 'billing_card_' + field);
  hiddenInput.setAttribute('value', token.card[field]);
  form.appendChild(hiddenInput);
}

// ===============
// The following 2 functions are for the Update Payment Method (aka "source") form:

function stripeSourceHandler(source) {
  // Insert the source ID into the form so it gets submitted to the server
  var form = document.getElementById('payment-form');
  var hiddenInput = document.createElement('input');
  hiddenInput.setAttribute('type', 'hidden');
  hiddenInput.setAttribute('name', 'stripeSource');
  hiddenInput.setAttribute('value', source.id);
  form.appendChild(hiddenInput);

  ["brand", "exp_month", "exp_year", "last4"].forEach(function(field) {
    addFieldToPaymentMethodForm(form, source, field);
  });

  // Submit the form
  form.submit();
}

function addFieldToPaymentMethodForm(form, source, field) {
  var hiddenInput = document.createElement('input');
  hiddenInput.setAttribute('type', 'hidden');
  hiddenInput.setAttribute('name', 'billing_card_' + field);
  hiddenInput.setAttribute('value', source.card[field]);
  form.appendChild(hiddenInput);
}





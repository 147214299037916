import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['toggler', 'reveal', 'memberlist']

  reveal() {
    this.revealTarget.classList.remove("hidden");
    if (this.hasMemberlistTarget) {
      this.memberlistTarget.classList.remove("hidden");
    }
  }

  hide() {
    this.revealTarget.classList.add("hidden");
    if (this.hasMemberlistTarget) {
      this.memberlistTarget.classList.add("hidden");
    }
  }
  
  showRecurringSettings(event) {
    event.stopPropagation()
    document.querySelector("#project-settings .dropdown-content.open-to-left").classList.remove("hidden")
    document.querySelector("#project-settings #project-recurrence").click()
  }

}
// This controller only handles the visual switching of checked and unchecked states, so that this is seen by the user as fast as possible.
// Other stuff that can happen slower is handled by the ajax action javascript (complete.js and uncomplete.js)

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['referencedTask', 'referencedTaskBlock', 'fieldStatus', 'fieldValue', 'stepBlockElement', 'criteriaElement'];

  connect() {
    this.showHideValue();
  }

  getTaskBlocks() {
    if(this.hasReferencedTaskTarget) {
      var processTemplate = event.target.getAttribute("data-process-template-id");
      var stepTemplate = this.referencedTaskTarget.value;
      
      if(stepTemplate) {
        fetch(`/${accountId}/process_templates/${processTemplate}/step_templates/${stepTemplate}/list_step_blocks`,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          return response.json();
        }).then((json) => {
          this.referencedTaskBlockTarget.innerHTML = '';
          if (this.hasStepBlockElementTarget) {
            this.stepBlockElementTarget.classList.remove("hidden");
          }
          this.criteriaElementTarget.classList.remove("hidden");
          this.referencedTaskBlockTarget.insertAdjacentHTML("beforeend", this.constructOptions(json));
        })
      }
    }
  }
  
  constructOptions(json) {
    var options = "";
    for(var step_block of json.step_blocks){
      options += `<option value="${step_block.id}">${step_block.label}</option> `;
    }
    return options;
  }

  showHideValue() {
    if(this.hasFieldStatusTarget && this.hasFieldValueTarget) {
      if(this.fieldStatusTarget.value === "must_contain" || this.fieldStatusTarget.value === "must_not_contain") {
        this.fieldValueTarget.classList.remove("hidden");
      }
      else {
        this.fieldValueTarget.classList.add("hidden");
      }
    }
  }

}
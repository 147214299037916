// <div class="tabs" 
//      data-controller="tabs" 
//      data-tabs-active-tab-id="246" 
//      data-tabs-tabs-id="unique-name-for-this-tabs-system">
//   <div class="tab-toggles">
//     <span class="tab" 
//           data-target="tabs.tab" 
//           data-action="click->tabs#activateTab" 
//           data-tabid="123">Tab 1 Name</span>
//     <span class="tab" 
//           data-target="tabs.tab" 
//           data-action="click->tabs#activateTab" 
//           data-tabid="456">Tab 2 Name</span>
//     <span class="tab" 
//           data-target="tabs.tab" 
//           data-action="click->tabs#activateTab" 
//           data-tabid="789">Tab 3 Name</span>
//   </div><!--/ .tab-toggles -->
//   <div class="tab-panels">
//     <div class="tab-panel" 
//          data-target="tabs.tabPanel" 
//          data-panelid="123">
//       Panel 1 Content
//     </div><!-- .tab-panel -->
//     <div class="tab-panel" 
//          data-target="tabs.tabPanel" 
//          data-panelid="456">
//       Panel 2 Content
//     </div><!-- .tab-panel -->
//     <div class="tab-panel" 
//          data-target="tabs.tabPanel" 
//          data-panelid="789">
//       Panel 3 Content
//     </div><!-- .tab-panel -->
//   </div><!--/ .tab-panels -->
// </div><!--/ .tabs -->

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "tab", "tabPanel" ]

  connect() {
    var tabs_id = this.data.get("tabs-id");

    if (localStorage.getItem(tabs_id) != null) {
      this.data.set("active-tab-id", localStorage.getItem(tabs_id));
    }

    var active_tab_id = this.data.get("active-tab-id");
    if (this.hasTabTarget) {
      var active_tabs = this.element.querySelectorAll(".tab[data-tabid='"+active_tab_id+"']");
    }
    if (this.hasTabPanelTarget) {
      var active_panels = this.element.querySelectorAll(".tab-panel[data-panelid='"+active_tab_id+"']");
    }
    
    if (active_tabs != null) {
      for (let active_tab of active_tabs) {
        active_tab.classList.add("active");
      }
      for (let active_panel of active_panels) {
        active_panel.classList.add("active");
      }
    } else {
      var first_found_tab = document.querySelector(".tab-panel");
      if (first_found_tab != null) {
        first_found_tab.classList.add("active");
        if (this.hasTabTarget) {
          this.element.querySelector(".tab[data-tabid='"+first_found_tab.dataset.panelid+"']").classList.add("active");
        }
      }
    }
  }

  activateTab(event) {

    var tabs_id = this.data.get("tabs-id");
    var active_tab_id = this.data.get("active-tab-id");
    var tabs = this.tabTargets
    var tab_id = event.target.closest(".tab").dataset.tabid;
    var panels = this.tabPanelTargets

    for (let tab of tabs) {
      if (tab.dataset.tabid == tab_id) {
        tab.classList.add("active");
        this.data.set("active-tab-id", tab_id);
        localStorage.setItem(tabs_id, tab_id);
      } else {
        tab.classList.remove("active");
      }
    }

    for (let panel of panels) {
      if (panel.dataset.panelid === tab_id || panel.dataset.panelid === event.target.closest(".tab").dataset.tabid ) { 
        panel.classList.add("active");
      } else {
        panel.classList.remove("active");
      }
    }

  }

}
import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['dragContainer']

  connect() {
    var dragula = require('dragula');
    
    this.drake = dragula({
      containers: Array.from(this.dragContainerTargets),
      invalid: (el, handle) => el.classList.contains('no-drag')
    });
    
    this.drake.on('drop', this.changePosition.bind(this))  
  }
  
  changePosition(el, target, source, sibling) {
    var stepList = document.querySelector(".steps");
    var processTemplateId = stepList.getAttribute("data-process-template-id");
    var stepTemplateId = this.dragContainerTarget.getAttribute("data-step-template-id");
    var elementId = el.getAttribute("data-block-id");
    var siblingId = null;

    // There might be a hidden input element near the dropped task block
    // so we will get the immediate next element of input element
    if(sibling.tagName === "INPUT") {
      sibling = sibling.nextElementSibling
    }
    
    if(sibling !== null) {
      siblingId = sibling.getAttribute("data-block-id")
    }
    let data = { id: elementId, sibling_id: siblingId };

    fetch(`/${accountId}/process_templates/${processTemplateId}/step_templates/${stepTemplateId}/step_blocks/${elementId}/update_position`,{
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if (!response.ok) {
        throw response
      }
      return response.json();
    }).then((responseJson) => {
      if(responseJson.sibling_present === true) {
        sibling = el.previousElementSibling
      }

      let elStepSettings = el.querySelector(".block-settings");
      let siblingStepSettings = sibling.querySelector(".block-settings");

      elStepSettings.outerHTML = responseJson.current_block_setting;
      siblingStepSettings.outerHTML = responseJson.sibling_block_setting;
    }).catch((error) => {
      console.log(error)
    })
  }

}
import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["select", "toggleable"]

  toggle(event) {
    // Hide all toggleable divs
    var toggleable_divs = this.toggleableTargets;
    for (let toggleable_div of toggleable_divs) {
      toggleable_div.classList.add("hidden");
    }

    // Unhide the one that was selected
    var selected_option = this.selectTarget.options[this.selectTarget.selectedIndex];
    var toggle_id = selected_option.dataset.toggleId;
    var div_to_toggle = this.element.querySelector("#"+toggle_id);
    div_to_toggle.classList.remove("hidden");
  }

}
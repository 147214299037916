import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['dragContainer']

  connect() {
    var dragula = require('dragula');

    this.drake = dragula({
      containers: Array.from(this.dragContainerTargets),
      invalid: (el, handle) => el.classList.contains('no-drag')
    });

    this.drake.on('drop', this.changePosition.bind(this))
  }

  changePosition(el, target, source, sibling) {
    var elementId = el.getAttribute("data-client-attr-template-id")
    var siblingId = null;

    if(sibling !== null) {
      siblingId = sibling.getAttribute("data-client-attr-template-id")
    }

    let data = { id: elementId, sibling_id: siblingId };

    fetch(`/${accountId}/client_attributes/${elementId}/update_position`, {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if (!response.ok) {
        throw response
      }
      return response.json();
    }).then((responseJson) => {
      if(responseJson.sibling_present === true) {
        sibling = el.previousElementSibling
      }

      let elStepSettings = el.querySelector(".dropdown-content");
      let siblingStepSettings = sibling.querySelector(".dropdown-content");
      elStepSettings.outerHTML = responseJson.current_template_setting;
      siblingStepSettings.outerHTML = responseJson.sibling_template_setting;
    }).catch((error) => {
      console.log(error)
    })
  }

}
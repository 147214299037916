import { Controller } from 'stimulus';
import { createPopper } from '@popperjs/core';

export default class extends Controller {

  static targets = ['tourStep']

  connect() {
  }

  show_step_1() {
    const tasks = document.querySelector('.process');
    const tasks_tourstop = document.querySelector('#tour-step-1');
    createPopper(tasks, tasks_tourstop, {
                  placement: 'top-start',
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [8, 35],
                      },
                    },
                  ]}
                );
    
    this.hide_all();
    document.querySelector('#tour-step-1').classList.remove("hidden");
  }

  show_step_2() {
    const edit_template_link = document.querySelector('a.edit-template-link');
    const edit_template_link_tourstop = document.querySelector('#tour-step-2');
    createPopper(edit_template_link, edit_template_link_tourstop, {
                  placement: 'left',
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 9],
                      },
                    },
                  ]}
                );

    this.hide_all();
    document.querySelector(".dropdown.task-list-settings").classList.add("open");
    document.querySelector(".dropdown.task-list-settings .dropdown-content").classList.add("tour-force-show");
    document.querySelector('#tour-step-2').classList.remove("hidden");
  }

  start() {
    var modal = document.querySelector(".modal#welcome-to-tour");
    modal.parentNode.removeChild(modal);
  }

  finish() {
    this.hide_all();
  }

  hide_all() {
    for (let tourstep of this.tourStepTargets) {
      tourstep.classList.add("hidden");
    }
    var forced_show_elements = document.querySelectorAll(".tour-force-show");
    for (let element of forced_show_elements) {
      element.classList.remove("tour-force-show");
    }
  }

}
import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [ "toggler", "cancellableElement" ]

  cancelCreate(event) {
    var new_list_div = event.target.closest(".new-kanban-list");
    new_list_div.parentNode.removeChild(new_list_div);

    var kanban_board = document.querySelector(".kanban-board");
    if (kanban_board.classList.contains("lists-count-4")) {
      kanban_board.classList.remove("lists-count-4");
      kanban_board.classList.add("lists-count-3");
    }
  }

  cancelUpdate(event) {
    var list_header = event.target.closest(".kanban-list-header");
    list_header.classList.remove("editing");
    list_header.querySelector(".list-title").classList.remove("hidden");
    list_header.querySelector("#kanban-list-settings").classList.remove("hidden");
    this.cancellableElementTarget.parentNode.removeChild(this.cancellableElementTarget);
  }

  showToggler() {
    if (this.hasTogglerTarget) {
      this.togglerTarget.classList.remove("hidden");
    }
  }

}
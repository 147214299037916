// <div class="field radios-toggle" 
//      data-controller="radios-toggle" 
//      data-radios-toggle-selection="true">
//   <%= f.label "What is your selection?", class: "set-label" %>
//   <div class="radios-toggle-wrap">
//     <label data-action="click->radios-toggle#select">
//       <%= f.radio_button :column_name, false %>
//       <span class="text">False</span>
//     </label>
//     <label data-action="click->radios-toggle#select">
//       <%= f.radio_button :column_name, true %>
//       <span class="text">True</span>
//     </label>
//   </div><!--/ .radios-toggle-wrap -->
// </div><!--/ .field.radios-toggle -->

import { Controller } from 'stimulus'

export default class extends Controller {


  connect() {
    var selection = this.data.get("selection");

    var selected_radio = this.element.querySelector("input[value="+selection+"]");

    if (selected_radio) {
      var label = selected_radio.closest("label");
      label.classList.add("selected");
    }
  }

  select(event) {

    var options = this.element.querySelectorAll(".radios-toggle-wrap label");
    var selected_option = event.target.closest("label");
    for (let option of options) {
      option.classList.remove("selected");
    }
    selected_option.classList.add("selected");
  }

}
import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['attribute']

  toggle(event) {
    var attribute = event.currentTarget.dataset.attribute
    window.location.search += '&order_by='+attribute;
  }

}
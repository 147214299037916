require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("custom/subscriptions")
require("custom/trix-autolinks")
require("custom/trix-editor-overrides")

import flatpickr from "flatpickr"

import "controllers"

require("trix")
require("@rails/actiontext")

import Trix from 'trix'

Trix.config.attachments.preview.caption.name = false;
Trix.config.attachments.preview.caption.size = false;
import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['step']

  connect() {
    var steps = this.stepTargets;
    for (let step of steps) {
      var step_expander_id = step.dataset.stepExpanderId;
      if (localStorage.getItem(step_expander_id) != null) {
        step.dataset.stepExpanderStatus = localStorage.getItem(step_expander_id);
      }
      var current_step_status = step.dataset.stepExpanderStatus;
      step.classList.add(current_step_status);
      step.closest("li").classList.add(current_step_status);
      if (current_step_status == "minimized") {
        var step_role_form = step.querySelector(".step-settings .set-role form");
        if (step_role_form) {
          var new_step_role_form_url = step_role_form.action + "?minimized=true";
          step_role_form.action = new_step_role_form_url;
        }
      } else { // this step is expanded
        step.classList.add("no-drag");
      }
    }
  }

  toggle(event) {
    var step = event.target.closest(".step");
    var step_expander_id = step.dataset.stepExpanderId;
    var current_step_status = step.dataset.stepExpanderStatus;
    var parent_li = step.closest("li");

    if (current_step_status == "expanded") {
      step.classList.remove("expanded");
      parent_li.classList.remove("expanded", "no-drag");
      step.classList.add("minimized");
      parent_li.classList.add("minimized");

      var step_title = step.querySelector(".step-title");
      step_title.classList.remove("editing");

      step.dataset.stepExpanderStatus = "minimized";
      localStorage.setItem(step_expander_id, "minimized");
    } else {
      step.classList.add("expanded");
      parent_li.classList.add("expanded", "no-drag");
      step.classList.remove("minimized");
      parent_li.classList.remove("minimized");
      step.dataset.stepExpanderStatus = "expanded";
      localStorage.setItem(step_expander_id, "expanded");
    }
  }

  open(event) { 
    // used on clicking elements to edit them, 
    // so it expands the step, regardless of whether it was already expanded or not.

    var step = event.target.closest(".step");
    var step_expander_id = step.dataset.stepExpanderId;
    var parent_li = step.closest("li");
    
    step.classList.add("expanded");
    step.classList.remove("minimized");
    parent_li.classList.add("expanded", "no-drag");
    parent_li.classList.remove("minimized");
    step.dataset.stepExpanderStatus = "expanded";
    localStorage.setItem(step_expander_id, "expanded");

  }

  open_all() {
    var closed_steps = this.element.querySelectorAll("li.saved-step:not(.expanded)");
    for (let closed_step of closed_steps) {
      closed_step.querySelector(".expander").click();
    }
  }

  close_all() {
    var open_steps = this.element.querySelectorAll("li.expanded");
    for (let open_step of open_steps) {
      open_step.querySelector(".expander").click();
    }
  }

}
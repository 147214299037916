import { Controller } from 'stimulus'

export default class extends Controller {

  // The 'single' target's existence is used to distinguish between the index page items and the destination pages.
  static targets = [ "single", "deleteProject", "updateBoard" ]

  connect() {
    if (this.hasSingleTarget) {
      var slug = this.data.get("slug");
      var name = this.data.get("name");
      localStorage.setItem(name, slug);
    }
    
    if(this.hasDeleteProjectTarget) {
      var delete_link = this.element.querySelector("a");
      var delete_project_url = delete_link.href;
      var name = this.data.get("name");
      var lastBoardView = localStorage.getItem(name);
      if (lastBoardView !== "list") {
        // Always direct to kanban view unless localstorage is set to 'list'
        delete_link.href = delete_project_url+"&from_kanban=true"; 
      } 
    }

    if(this.hasUpdateBoardTarget) {
      var name = this.data.get("name");
      var lastBoardView = localStorage.getItem(name);
      if (lastBoardView !== "list") {
        // Always direct to kanban view unless localstorage is set to 'list'
        var form_url = this.element.action;
        this.element.action = form_url+"&from_kanban=true";
      } 
    }

  }

  // Used to remember a board's view when navigating to a board
  redirectToLastVisited(event) {
    var name = this.data.get("name");
    event.preventDefault();
    var base_path = this.data.get("base-path");
    var lastBoardView = localStorage.getItem(name);
    if (lastBoardView == "list") {
      var slug = ""
    } else {
      var slug = "kanban" // Always direct to kanban view unless localstorage is set to 'list'
    }
    window.location.href = "/"+base_path+"/"+slug;
  } 

}

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['static', 'animated']

  startLoading() {
    this.staticTarget.classList.add("hidden");
    this.animatedTarget.classList.remove("hidden");
  }

  finishedLoading() {
    this.staticTarget.classList.remove("hidden");
    this.animatedTarget.classList.add("hidden");
  }

}
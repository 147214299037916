// This controller only handles the visual switching of checked and unchecked states, so that this is seen by the user as fast as possible.
// Other stuff that can happen slower is handled by the ajax action javascript (complete.js and uncomplete.js)

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['referencedTask', 'referencedTaskBlock', 'fieldStatus', 'fieldValue', 'taskBlockElement', 'criteriaElement'];

  connect() {
    this.showHideValue();
  }

  getTaskBlocks() {
    if(this.hasReferencedTaskTarget) {
      var project_id = event.target.getAttribute("data-project-id");
      var task_list_id = event.target.getAttribute("data-task-list-id");
      var task_id = this.referencedTaskTarget.value;
      
      if(task_id) {
        fetch(`/${accountId}/projects/${project_id}/task_lists/${task_list_id}/tasks/${task_id}/list_task_blocks`,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          return response.json();
        }).then((json) => {
          this.referencedTaskBlockTarget.innerHTML = '';
          this.taskBlockElementTarget.classList.remove("hidden");
          this.criteriaElementTarget.classList.remove("hidden");
          this.referencedTaskBlockTarget.insertAdjacentHTML("beforeend", this.constructOptions(json));
        })
      }
    }
  }
  
  constructOptions(json) {
    var options = "";
    for(var task_block of json.task_blocks){
      options += `<option value="${task_block.id}">${task_block.label}</option> `;
    }
    return options;
  }

  showHideValue() {
    if(this.hasFieldStatusTarget && this.hasFieldValueTarget) {
      if(this.fieldStatusTarget.value === "must_contain" || this.fieldStatusTarget.value === "must_not_contain") {
        this.fieldValueTarget.classList.remove("hidden");
      }
      else {
        this.fieldValueTarget.classList.add("hidden");
      }
    }
  }

}
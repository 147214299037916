import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['requiredTextarea', 'submitButton']

  fill_in_textarea() {
    var count = this.requiredTextareaTarget.value.length
    if (count > 0) {
      this.submitButtonTarget.classList.remove("button-disabled");
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.classList.add("button-disabled");
      this.submitButtonTarget.disabled = true;
    }
  }

}
// <input id="email" 
//            type="hidden" 
//            name="email" 
//            value="" 
//            data-controller="prefill-input" 
//            data-prefill-input-param="email" />

import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {

    // Gets the URL params and puts them into a hash
    var getParams = function (url) {
      var params = {};
      var parser = document.createElement('a');
      parser.href = url;
      var query = parser.search.substring(1);
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
      return params;
    };

    // fill the input with the value of the param
    var url = window.location.href;
    var param_name = this.data.get('param'); 
    var value = getParams(url)[param_name];
    if (value) {
      this.element.value = value;
      this.element.autofocus = false;
      var password_field = document.querySelector("input#user_password");
      password_field.focus();
    }    
  }

}
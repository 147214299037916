import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
  }

  setOrder(event) {
    event.preventDefault();
    var sort_link = event.target.closest('a');
    var sort_by_attribute = sort_link.dataset.attribute;
    var current_url = window.location.href;

    // Check if the current url has params (has '?') or not
    var array = current_url.split('?');
    if (array.length > 1 && array[1] !== '') {
      window.location.href = current_url+"&"+sort_by_attribute;
    } else {
      window.location.href = current_url+"?"+sort_by_attribute;
    }
    
  }

}
// This is used when you have a selection dropdown and the user clicks items to select them, resulting in those items' IDs being added to an array.

// Here is the code taken from the task action 'notify' field, where user selects account_members to include in the notifications list:

// <div class="field" 
//      data-controller="selection-array" 
//      <% if local_assigns[:thing].present? %> 
//       data-account-members-value="<%= action.account_members %>"
//      <% end %>>

//   <div class="dropdown" data-controller="dropdown" id="select-account_members">
//     <div class="dropdown-toggle button-small button-secondary" 
//          data-action="click->dropdown#toggle click@window->dropdown#hide">
//       Select users in <%= @account.name %> to notify
//       <span class="icon icon-right icon-arrow-down2"></span>
//     </div>
//     <div class="dropdown-content selection hidden" 
//          data-target="dropdown.content" 
//          data-action="turbolinks:before-cache@window->dropdown#hide">
//       <div class="inner-options-list with-avatars">
//         <div class="selected-status" data-controller="selections-list">
//           <% @account.account_members.non_guests.each do |account_member| %>
//             <% if local_assigns[:action].present? && action.account_members.present?
//                  account_members_array = action.account_members.split(',') 
//                end %>
//             <a class="<%= 'selected' if ( (account_members_array.present?) && (account_members_array.include? account_member.id.to_s) ) %>"
//                data-action="click->selection-array#toggle" 
//                data-selectionid="<%= account_member.id %>">
//               <div class="avatar">
//                 <%= image_tag avatar_url(account_member.user) %>
//                 <div class="initials"><%= user_initials(account_member.user) %></div>
//               </div><!--/ .avatar -->
//               <div class="text"><%= account_member.user.display_name %></div>
//               <span class="icon icon-checkmark3"></span>
//             </a>
//           <% end %>
//         </div><!--/ .selected-status -->
//       </div><!--/ .inner-options-list.with-avatars -->
//     </div><!--/ .dropdown-content -->
//   </div><!--/ .dropdown -->

//   <% if local_assigns[:action].present? 
//       account_members_value = action.account_members 
//      end %>
//   <%= f.hidden_field :account_members, 
//                      value: account_members_value,
//                      data: {
//                       target: "selection-array.field"
//                      } %>
// </div><!--/ .field -->

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [ "field", "selections" ]

  toggle(event) {

    // get the clicked selection 
    var selection_element = event.target.closest("a");

    // toggle the .selected class
    if (selection_element.classList.contains("selected")) {
      selection_element.classList.remove("selected");
    } else {
      selection_element.classList.add("selected");
    }

    // get the id from the data attribute on the selected element
    var selected_id = selection_element.dataset.selectionid;

    // In the selections list, toggle the item that matches the ID of the selected element
    var selection = this.selectionsTarget.querySelector('.selected-list-item[data-selection-id="'+selected_id+'"]');
    if (selection.classList.contains("selected")) {
      selection.classList.remove("selected");
    } else {
      selection.classList.add("selected");
    }

    // build the array from the current value of the hidden field
    var selections_array = this.fieldTarget.value.split(',');

    // add or remove the selected item in the array
    if (selections_array.includes(selected_id)) {
      var selections_array = selections_array.filter(item => item !== selected_id)
    } else {
      selections_array.push(selected_id);
    }

    var list_wrapper = this.element.querySelector('.selected-status');
    if (selections_array.length == 0) {
      list_wrapper.classList.add("none-selected");
    } else {
      list_wrapper.classList.remove("none-selected");
    }

    // convert array to string and remove the first comma
    var selections_array = selections_array.join().replace(/^,/, '');

    // set the value of the field to the new array
    if (selections_array.length != 0) {
      this.fieldTarget.value = selections_array;
      this.element.querySelector('.dropdown').classList.remove("empty");
    } else {
      this.fieldTarget.value = "";
      this.element.querySelector('.dropdown').classList.add("empty");
    }

  }

}
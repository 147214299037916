
import { Controller } from 'stimulus'

export default class extends Controller {
  
  static targets = ['normalView', 'compactView'];

  connect() {
    var name = this.data.get("name");

    if (localStorage.getItem(name) === "compact") {
      this.compactViewTarget.classList.add("active");
      this.normalViewTarget.classList.remove("active");
      document.querySelector("body").classList.add("process-compact-view");
      document.querySelectorAll(".top-expander").forEach(function(element) {
        element.style.display = "none";
      });
    }
  }

  selectNormalView(event) {
    event.preventDefault();
    this.compactViewTarget.classList.remove("active");
    this.normalViewTarget.classList.add("active");
    document.querySelector("body").classList.remove("process-compact-view");
    var name = this.data.get("name");
    localStorage.setItem(name, "normal");

    document.querySelectorAll(".top-expander").forEach(function(element) {
      element.style.display = "block";
    });
  }

  selectCompactView(event) {
    event.preventDefault();
    this.compactViewTarget.classList.add("active");
    this.normalViewTarget.classList.remove("active");
    document.querySelector("body").classList.add("process-compact-view");
    var name = this.data.get("name");
    localStorage.setItem(name, "compact");
    document.querySelectorAll(".top-expander").forEach(function(element) {
      element.style.display = "none";
    });
  }

  
}
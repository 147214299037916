import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [ "row", "rowsWrapper", "default" ]

  connect() {
  }

  sort(event) {
    if (this.hasDefaultTarget) {
      if (event.type == "load") {
        var sorted_header = this.defaultTarget;
      } else { // it was a "click" event
        event.preventDefault();
        var sorted_header = event.target.closest("a");
        var attribute_id = sorted_header.dataset.attributeId;
      }
      
      var rows = this.rowTargets;
      var rows_wrapper = this.rowsWrapperTarget;

      sorted_header.classList.add("currently-sorted");
      rows_wrapper.classList.add("currently-sorted");

      if (sorted_header.classList.contains("sort-direction-desc")) {
        // Sort the rows array by the value of dataset.attributeValue in ascending order
        var sorted_rows = rows.sort(function(a, b) {
          return parseFloat(a.dataset.attributeValue) - parseFloat(b.dataset.attributeValue);  
        });
        // set the css 'order' attribute for each row, so that it sorts using flexbox
        sorted_rows.forEach(function (row, i) {
          row.style.order = i
        });
        // set the css class
        sorted_header.classList.add("sort-direction-asc");
        sorted_header.classList.remove("sort-direction-desc");

      } else {

        // Sort the rows array by the value of dataset.attributeValue in descending order
        var sorted_rows = rows.sort(function(a, b) {
          return parseFloat(b.dataset.attributeValue) - parseFloat(a.dataset.attributeValue);  
        });
        // set the css 'order' attribute for each row, so that it sorts using flexbox
        sorted_rows.forEach(function (row, i) {
          row.style.order = i
        });
        // set the css class
        sorted_header.classList.add("sort-direction-desc");
        sorted_header.classList.remove("sort-direction-asc");

      }
    }
        
  }

}
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['startCriteria', 'dueCriteria']
  
  connect() {
    if(this.hasStartCriteriaTarget && this.hasDueCriteriaTarget) {
      this.removeDependentOption()
    }
  }
  
  removeDependentOption() {
    if(this.hasStartCriteriaTarget && this.hasDueCriteriaTarget) {
      var startCriteriaLength = this.startCriteriaTarget.length;
      var dueCriteriaLength = this.dueCriteriaTarget.length;

      if (this.startCriteriaTarget.value === "this-task-due") {
        this.removeOptionInSelect(dueCriteriaLength, this.dueCriteriaTarget, "this-task-start")
      } else {
        this.addOptionInSelect(dueCriteriaLength, this.dueCriteriaTarget, "this-task-start", 'this task starts')
      }
      
      if(this.dueCriteriaTarget.value === "this-task-start") {
        this.removeOptionInSelect(startCriteriaLength, this.startCriteriaTarget, "this-task-due")
      } else {
        this.addOptionInSelect(startCriteriaLength, this.startCriteriaTarget, "this-task-due", 'this task is due')
      }
    }
  }
  
  removeOptionInSelect(optionLength, selectElement, optionValue) {
    var z;
    for(z = 0; z < optionLength; z++) {
      if(selectElement[z].value === optionValue) {
        selectElement.remove(z)
        break
      }
    }
  }
  
  addOptionInSelect(optionLength, selectElement, optionValue, optionText) {
    var rulePresent = false;
    var z;
    for(z = 0; z < optionLength; z++) {
      if(selectElement[z].value === optionValue) {
        rulePresent = true
      }
    }
    if(!rulePresent) {
      var optionIndex = this.data.get("task") ? 3 : 2;
      selectElement.add(new Option(optionText, optionValue), selectElement.options[optionIndex]);
    }
  }
}
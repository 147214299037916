import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    
    flatpickr(this.element, {
      enableTime: true,
      animate: false,
      wrap: true,
      disableMobile: "true",
      onOpen: function(dateStr, selectedDates, instance) {
        if(instance.element.classList.contains("has-value")) {
          this.element.querySelector(".display-datetime .datetime-actions .icon.clear").classList.remove("hidden");
        } else {
          this.element.querySelector(".display-datetime .datetime-actions .icon.clear").classList.add("hidden");
        }
      },
      onChange: function(dateStr, selectedDates, instance) {
        console.log(instance);
        if(instance.selectedDates.length == 0) {
          this.element.querySelector(".display-datetime .datetime .date").innerHTML = "";
          this.element.querySelector(".display-datetime .datetime .time").innerHTML = "";
          this.element.querySelector(".display-datetime .datetime-actions .icon.clear").classList.add("hidden");
        } else {
          var month = instance.monthElements[0].selectedOptions[0].text.substring(0,3);
          console.log(month);
          var day = instance.selectedDateElem.innerText;
          var year = instance.yearElements[0].value;
          var hour = instance.hourElement.valueAsNumber
          var minutes = instance.minuteElement.value
          var ampm = instance.amPM.innerText.toLowerCase();
          this.element.querySelector(".display-datetime .datetime .date").innerHTML = month+" "+day+", "+year;
          this.element.querySelector(".display-datetime .datetime .time").innerHTML = hour+":"+minutes+ampm;
          this.element.querySelector(".display-datetime .datetime-actions .icon.clear").classList.remove("hidden");
        }
        
      }
    })

  }

}
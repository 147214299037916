import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    var page_id = this.data.get("page-id");
    var page_name = this.data.get("page-name");

    // (optional) If you need to always include some params in addition to the sorting params, put those into the preload-params attribute.
    var preload_params = this.data.get("preload-params");

    var saved_sorting_attribute = localStorage.getItem(page_name+'-'+page_id+'-sorting');
    if (saved_sorting_attribute != null) {
      var url = window.location.href
      // this data attribute contains comma separated strings that, if present in the url, should prevent the page from redirecting to the sorting params
      var dont_sort_if_present = this.data.get("dont-sort-if-present");

      var dont_sort_array = dont_sort_if_present.split(",")

      // check to make sure the url doesn't contain any of the strings in the dont_sort_array
      if (!dont_sort_array.some(v => url.includes(v))) {

        // .hash checks for #sorted at the end of the url
        if(!window.location.hash) { 

          // on load, redirect to the page_path url + sorting params + #sorted (adds #sorted to prevent a redirect loop)
          var page_path = this.data.get("page-path");

          if (preload_params) {
            window.location.href = "/"+page_path+"?"+preload_params+"&order_by="+saved_sorting_attribute+"#sorted";
          } else {
            window.location.href = "/"+page_path+"?order_by="+saved_sorting_attribute+"#sorted";
          }

        }
      }
    }
  }

  setOrder(event) {
    event.preventDefault();
    var sort_link = event.target.closest('a');
    var sort_by_attribute = sort_link.dataset.attribute;
    var page_id = this.data.get("page-id");
    var page_name = this.data.get("page-name");

    // (optional) If you need to always include some params in addition to the sorting params, put those into the preload-params attribute.
    var preload_params = this.data.get("preload-params");

    localStorage.setItem(page_name+'-'+page_id+'-sorting', sort_by_attribute);
    var page_path = this.data.get("page-path");

    if (preload_params) {
      window.location.href = "/"+page_path+"?"+preload_params+"&order_by="+sort_by_attribute+"#sorted";
    } else {
      window.location.href = "/"+page_path+"?order_by="+sort_by_attribute+"#sorted";
    }
    
  }

}
import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['progressElement']

  connect() {
    if(this.element.querySelector("svg") !== null) {
      // Progress bar won't be created if it is already present in the element
      return;
    }
     
    var ProgressBar = require('progressbar.js');

    var completeness = (this.data.get("completeness")/100);

    var bar = new ProgressBar.Circle(this.progressElementTarget, {
      strokeWidth: 5,
      trailWidth: 5,
      easing: 'easeInOut',
      duration: 1400,
      svgStyle: null
    });

    bar.animate(completeness);  // Number from 0.0 to 1.0
  }

  hide() {
    this.progressElementTarget.querySelector("svg").classList.add("hidden");
  }

}
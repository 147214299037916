addEventListener("trix-initialize", event => new TrixAutoLinker(event.target));

var TrixAutoLinker = (function() {
  let PATTERN = undefined;
  let INPUT = undefined;
  let isValidURL = undefined;
  TrixAutoLinker = class TrixAutoLinker {
    static initClass() {
  
      PATTERN = new RegExp(`(https?://\\S+\\.\\S+)\\s`, 'ig');
  
      INPUT = document.createElement("input");
      INPUT.type = "url";
      INPUT.required = true;
  
      isValidURL = function(value) {
        INPUT.value = value;
        return INPUT.checkValidity();
      };
    }
    constructor(element) {
      this.autoLink = this.autoLink.bind(this);
      this.element = element;
      ({editor: this.editor} = this.element);
      this.element.addEventListener("trix-render", this.autoLink);
      this.autoLink();
    }

    autoLink() {
      return (() => {
        const result = [];
        for (let {url, range} of Array.from(this.getURLsWithRanges())) {
          if (this.getHrefAtRange(range) !== url) {
            const currentRange = this.editor.getSelectedRange();
            this.editor.setSelectedRange(range);
            if (this.editor.canActivateAttribute("href")) {
              this.editor.activateAttribute("href", url);
            }
            result.push(this.editor.setSelectedRange(currentRange));
          } else {
            result.push(undefined);
          }
        }
        return result;
      })();
    }

    getDocument() {
      return this.editor.getDocument();
    }

    getDocumentString() {
      return this.getDocument().toString();
    }

    getHrefAtRange(range) {
      return this.getDocument().getCommonAttributesAtRange(range).href;
    }

    getURLsWithRanges() {
      let match;
      const results = [];
      const string = this.getDocumentString();
      while ((match = PATTERN.exec(string))) {
        const url = match[1];
        if (isValidURL(url)) {
          const position = match.index;
          const range = [position, position + url.length];
          results.push({url, range});
        }
      }
      return results;
    }
  };
  TrixAutoLinker.initClass();
  return TrixAutoLinker;
})();

import { Controller } from 'stimulus'

export default class extends Controller {

  // The 'single' target's existence is used to distinguish between the index page items and the destination pages.
  static targets = [ "single" ]

  connect() {
    if (this.hasSingleTarget) {
      var slug = this.data.get("slug");
      var name = this.data.get("name");
      localStorage.setItem(name, slug);
    }
  }

  redirectToLastVisited(event) {
    var name = this.data.get("name");
    if (localStorage.getItem(name) !== null) {
      event.preventDefault();
      var base_path = this.data.get("base-path");
      var slug = localStorage.getItem(name);
      window.location.href = "/"+base_path+"/"+slug;
    }
  }

}

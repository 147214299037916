 // In the following example, 'resource'/'resources' is whatever the object to be listed and filtered by.
// Examples:  boards, process_groups, tags, etc.

// ...
// Rails Controller:

// def index
//   @resources = @account.resources.all
//   @resources = @account.resources.parameter_name(params[:parameter_name]) if params[:parameter_name].present?
// end

// ...
// View:

// <div class="filter-list" 
//      id="boards-list" 
//      data-controller="filter-list" 
//      data-target="filter-list.list" 
//      data-filter-list-parameter="parameter_name">

// <% @resources.each do |resource| %>
//   <div class="filter-item" 
//        data-target="filter-list.value" 
//        data-filter_list_value="<%= resource.id %>">
    
//     <%= link_to resources_path(parameter_name: toggle_filter(:parameter_name, resource.id)) do %>
//       <span class="text"><%= resource.name %></span>
//       <div class="filter-status">
//         <span class="icon icon-checkmark3"></span>
//       </div><!--/ .filter-status -->
//     <% end %>
    
//   </div><!--/ .filter-item -->
// <% end %>

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['value', 'list', 'addItemLink']

  initialize() {
    
    var params = location.search.substr(1).split('&'),
        url_params_hash = {};

    for(var i = 0; i < params.length; i++)
    {
        var temp = params[i].split('='),
            key = temp[0],
            val = temp[1];

        url_params_hash[key] = url_params_hash[key] || [];
        url_params_hash[key].push(val);
    }

    var filter_parameter = this.data.get("parameter")+"%5B%5D";

    if(filter_parameter in url_params_hash) {
      var filter_items = this.listTarget.querySelectorAll(".filter-item");
      for (let filter_item of filter_items) {
        var filter_item_value = filter_item.dataset.filter_list_value;
        if (url_params_hash[filter_parameter].includes(filter_item_value)) {
          filter_item.classList.add("active");
        } else {
          filter_item.classList.add("inactive");
        }
      }
    }

  }

  toggle(event) {
    var filter_item = event.target.closest(".filter-item");
    filter_item.classList.add("active");
  }

  hideNewForm(event) {
    event.stopImmediatePropagation(); // this line prevents the parent dropdown from closing when you just want to cancel the new form.

    this.addItemLinkTarget.classList.remove('hidden');
    var form_element = event.target.closest("form");
    form_element.parentNode.removeChild(form_element);
  }

  hideEditForm(event) {
    event.stopImmediatePropagation(); // this line prevents the parent dropdown from closing when you just want to cancel the edit form.
    
    event.target.closest(".filter-item").classList.remove("editing");
    var form_element = event.target.closest("form");
    form_element.parentNode.removeChild(form_element);
  }

}
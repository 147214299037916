import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['newStep']

  // Inserting a New Step inbetween 2 existing steps is handled via new.js.erb, instead of this stimulus controller.

  // Used for hiding the new step LI when 'cancel' is clicked.
  hideNewStep(event) {
    var this_new_step_li = event.target.closest("li.new-step")
    this_new_step_li.classList.add("hidden");
    var add_step_toggle = this.element.querySelector(".add-step-toggle.hidden");
    if (add_step_toggle) {
      add_step_toggle.classList.remove("hidden");
    }

    // the tabs stimulus controller stored the tab that was last viewed.
    // since we always want new steps to default to the description tab, 
    // clear that localstorage item for new steps.
    var tabs_id = event.target.closest(".step").dataset.tabsTabsId; // gets value of data-tabs-tabs-id, which was there for the tabs stimulus controller
    if (localStorage.getItem(tabs_id) != null) {
      localStorage.removeItem(tabs_id);
    }
  }

}
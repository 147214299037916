import { Controller } from 'stimulus'
import Trix from "trix";
import Tribute from "tributejs";

export default class extends Controller {

  static targets = ['toolbar', 'editor']

  connect() {
    // Editor and Toolbar targets may not be present always as this controller is used in places where Trix editor is not present
    if (this.hasEditorTarget && this.hasToolbarTarget) {

      if(this.data.has("enableMentions")) {
        this.initializeTribute()
      }

      let editor = this.editorTarget;
      let toolbar = this.toolbarTarget;
      let ttools = toolbar.querySelector(".trix-button-group--text-tools");
      let dialogs = toolbar.querySelector(".trix-dialogs");
      let trixId = editor.trixId;

      let dialogContent = `
        <div class="trix-dialog trix-dialog--attach" data-trix-dialog="attach" data-trix-dialog-attribute="attach">
          <div class="trix-dialog__attach-fields">
            <div class="trix-element-group">
              <input type="file" class="trix-input trix-input--dialog">
              <div class="trix-button-group">
                <input type="button" class="trix-button trix-button--dialog"
                  onclick="
                    var trix = document.querySelector('trix-editor[trix-id=\\'${trixId}\\']');
                    var fileElm = this.parentElement.parentElement.querySelector('input[type=\\'file\\']');
                    if ( fileElm.files.length == 0 ) {
                      console.log('nothing selected');
                      return;
                    }
                    var file = fileElm.files[0];
                    trix.editor.insertFile(file);
                  "
                  value="Insert" data-trix-method="removeAttribute">
                <input type="button" class="trix-button trix-button--dialog" value="Cancel" data-trix-method="removeAttribute">
              </div>
            </div>
          </div>
        </div>
      `;

      let iFrameDialogContent = `
        <div class="trix-dialog trix-dialog--attach" data-trix-dialog="embed" data-trix-dialog-attribute="embed">
          <div class="trix-dialog__attach-fields">
            <div class="trix-element-group">
              <input type="text" class="trix-input trix-input--dialog" placeholder="Embed code...">
              <div class="trix-button-group">
                <input type="button" class="trix-button trix-button--dialog" value="Insert" data-controller="trix_scripts" data-action="click->trix-scripts#embedIframe">
                <input type="button" class="trix-button trix-button--dialog" value="Cancel" data-trix-method="removeAttribute"  id="trix-cancel-button\'${trixId}\'">
              </div>
            </div>
            <div id="trix-embed-input-error-${trixId}" class="trix-text-error"></div>
          </div>
        </div>
      `;
      
      let linkContent = `<div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
                          <div class="trix-dialog__link-fields">
                            <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="Enter a URL…" aria-label="URL" required="" data-trix-input="" disabled="disabled">
                            <div class="trix-button-group">
                              <input type="button" class="trix-button trix-button--dialog" value="Link" data-trix-method="setAttribute">
                              <input type="button" class="trix-button trix-button--dialog" value="Unlink" data-trix-method="removeAttribute">
                            </div>
                          </div>
                        </div>`


      // Sometimes the .trix-dialogs div doesn't load, so if that's the case, load it into the DOM
      if (!dialogs) {
        toolbar.insertAdjacentHTML("afterbegin", "<div class='trix-dialogs inserted' data-trix-dialogs></div>");
        var insertedDialogDiv = toolbar.querySelector(".trix-dialogs");
        insertedDialogDiv.insertAdjacentHTML("beforeend", dialogContent);
        insertedDialogDiv.insertAdjacentHTML("beforeend", iFrameDialogContent);
        
        var linkDialogDiv = toolbar.querySelector(".trix-dialog__link-fields");
        if(linkDialogDiv === undefined || linkDialogDiv === null ) {
          insertedDialogDiv.insertAdjacentHTML("beforeend", linkContent);
        }
      } else {
        dialogs.insertAdjacentHTML("beforeend", dialogContent);
        dialogs.insertAdjacentHTML("beforeend", iFrameDialogContent);
      }
    }
  }

  disconnect() {
    if(this.hasEditorTarget && this.tribute)
      this.tribute.detach(this.editorTarget)
  }

  // This function will add target="_blank" attribute to open clicked hyperlink in new browser tab.
  //
  // This function will loop back to each parent of the passed element until it finds hyperlink tag or trix-content class
  // to handle clicks in the link with Bold, Italic or any other styles.
  handleLinkClick(event) {
    let eventTarget = event.target;

    while(eventTarget.className !== "trix-content" && eventTarget.className !== "rich-text-content") {
      if(eventTarget.tagName.toLowerCase() === 'a') {
        eventTarget.setAttribute("target", "_blank");
        break;
      }

      eventTarget = eventTarget.parentElement;
    }
  }

  embedIframe(event) {
    let editor  = this.editorTarget;
    let trixId  = editor.trixId;
    let trix = document.querySelector(`trix-editor[trix-id=\"${trixId}\"]`);
    let content = event.target.parentElement.parentElement.querySelector('input[type=\'text\']');
    let embedInputError = document.getElementById(`trix-embed-input-error-${trixId}`);

    if(this.validEmbed(content)) {
      let data = { embed: { content: content.value }, account_id: accountId };

      fetch("/embeds",{
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (!response.ok) {
          throw response
        }
        return response.json();
      }).then((json) => {
        const attachment = new Trix.Attachment({
          content: json.content,
          sgid: json.sgid,
          contentType: 'application/octet-stream'
        });

        trix.editor.insertAttachment(attachment);

        // Close dialog box and reset values
        document.getElementById(`trix-cancel-button'${trixId}'`).click();
        content.value = '';
        embedInputError.innerHTML = '';
      }).catch((error) => {
        error.json().then( errorMessage => {
          embedInputError.innerHTML = errorMessage["content"];
        })
      })
    } else {
      embedInputError.innerHTML = "Please enter a valid embed code";
    }
  }

  validEmbed(content) {
    return typeof(content) != 'undefined' && content != null &&
        content.value.indexOf("iframe") > -1 && content.value.indexOf("/iframe") > -1
  }
  
  initializeTribute() {
    this.userCollection = undefined
    
    if(this.hasEditorTarget) {
      this.editor = this.editorTarget.editor
      
      this.tribute = new Tribute({
        allowSpaces: true,
        lookup: 'display_name',
        menuItemTemplate: function (item) {
          return item.original.content
        },
        values: this.fetchUsers.bind(this),
      })
      
      this.tribute.attach(this.editorTarget)
      this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
      this.editorTarget.addEventListener("tribute-replaced", this.replaced)
    }
  }
  
  fetchUsers(text, callback) {
    if (this.userCollection == undefined) {
      fetch(`/${accountId}/mentions.json`)
        .then(response => response.json())
        .then(users => {
          this.userCollection = users
          callback(users)
        })
        .catch(error => callback([]))
    } else {
      callback(this.userCollection)
    }
  }

  replaced(e) {
    let mention = e.detail.item.original
    let attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content,
      contentType: 'application/octet-stream'
    })
    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ")
  }

  _pasteHtml(html, startPos, endPos) {
    let position = this.editor.getPosition()
    this.editor.setSelectedRange([position - (endPos - startPos), position])
    this.editor.deleteInDirection("backward")
  }
}

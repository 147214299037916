import { Controller } from 'stimulus'

export default class extends Controller {

  // Inserting a new item is handled via new.js.erb, not this stimulus controller.

  static targets = ['itemsList', 'hideWhileCreating', 'hideWhileEditing']

  // Used for hiding the new item form creation or when 'cancel' is clicked.
  hideNewItem(event) {
    var new_item_form = event.target.closest(".item-form");
    var page_container = document.querySelector(".container");
    document.querySelector("body").classList.remove("item-form-visible");
    new_item_form.parentNode.removeChild(new_item_form);
    if (!this.hasItemsListTarget) {
      this.element.querySelector('.help-box').classList.remove("hidden");
    }
    if (this.hasHideWhileCreatingTarget) {
      var show_elements = this.hideWhileCreatingTargets;
      for (let show_element of show_elements) {
        show_element.classList.remove("hidden");
      }
    }
  }

  hideEditItem(event) {
    var item_div = event.target.closest(".items-list-item");
    var edit_item_form = item_div.querySelector(".item-form");
    var page_container = document.querySelector(".container");
    document.querySelector("body").classList.remove("item-form-visible");
    edit_item_form.parentNode.removeChild(edit_item_form);
    item_div.querySelector(".item-content").classList.remove("hidden");
    item_div.classList.remove("no-drag");
    if (this.hasHideWhileEditingTarget) {
      var show_elements = this.hideWhileEditingTargets;
      for (let show_element of show_elements) {
        show_element.classList.remove("hidden");
      }
    }
  }

}
// This controller only handles the visual switching of checked and unchecked states, so that this is seen by the user as fast as possible.
// Other stuff that can happen slower is handled by the ajax action javascript (complete.js and uncomplete.js)

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['step'];

  connect() {
    if(this.hasStepTarget && this.stepTarget.classList.contains("expanded")) {
      this.load_task_data(this.stepTarget);
    }
  }
  
  load_task_data(step) {
    let automation_panel = step.querySelector(".step-sidebar .step-nav .tab.icon.icon-power2");
    let activity_panel = step.querySelector(".step-sidebar .step-nav .tab.icon.icon-bubble-lines3");

    if(automation_panel && automation_panel.classList.contains("active")) {
      automation_panel.click();
    } else if(activity_panel && activity_panel.classList.contains("active")) {
      activity_panel.click();
    }
  }

}
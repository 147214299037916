import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['radioButton']

  status(event) {
    var all_labels = this.element.querySelectorAll("label");
    for (let label of all_labels) {
      label.classList.remove("selected");
      label.classList.add("unselected");
    }
    var this_label = event.target.closest("label");
    this_label.classList.remove("unselected");
    this_label.classList.add("selected");
  }

}
// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

// AppSignal Config
import Appsignal from "@appsignal/javascript"
import { installErrorHandler } from "@appsignal/stimulus"
const appsignal = new Appsignal({ 
  key: "7f8141bb-da97-4125-9fc0-acaed4d95a9a"
})
// End AppSignal Config

const application = Application.start()

// Add AppSignal to application
installErrorHandler(appsignal, application)

const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
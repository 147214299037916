import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['selectMonthly', 'selectYearly', 'monthlyInfo', 'yearlyInfo', 'updatePlanButton']

  show_monthly() {
    this.monthlyInfoTarget.classList.remove("hidden");
    this.yearlyInfoTarget.classList.add("hidden");
  }

  show_yearly() {
    this.monthlyInfoTarget.classList.add("hidden");
    this.yearlyInfoTarget.classList.remove("hidden");
  }

  update_plan() {
    this.updatePlanButtonTarget.classList.remove("button-disabled");
    this.updatePlanButtonTarget.disabled = false;
  }

}
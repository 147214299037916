// misc JS for steps/tasks

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['editable']

  editMe(event) {
    // Turn all editable parts back to 'viewing' state
    var editable_parts = this.editableTargets
    for (let editable_part of editable_parts) {
      editable_part.classList.remove("editing");
      editable_part.classList.add("viewing");
    }

    // Hide/reset all configurations (block content, automations) forms
    resetConfigurationForms(this.element);

    // Turn THIS editable part to 'editing' state
    var edit_me = event.target.closest("[data-target*='step--step.editable']");
    edit_me.classList.add("editing");
    edit_me.classList.remove("viewing");

  }

  focusMe(event) {
    var block = event.target.closest(".block");
    var block_id = block.dataset.blockId;
    var input = this.element.querySelector(".block-"+block_id+"-input");
    var rich_text_modal = event.target.closest(".trix-dialog");
    var rich_text_button = event.target.closest("button[data-trix-action='link']");

    if (!rich_text_modal) {

      // focus the input
      if (input && !rich_text_button) {
        input.focus();
      }

      // unfocus any other blocks that are currently focused
      var focused_blocks = this.element.querySelectorAll(".block.focused");
      for (let focused_block of focused_blocks) {
        focused_block.classList.remove("focused");
      }
      // focus this block
      block.classList.add("focused");
    }
  }

  submitConfigureBlock(event) {
    var block_id = event.target.dataset.blockId;
    var form = event.target.closest("form.step-content-form");
    var form_action = form.action;
    var new_form_action_url = form_action + "?configured_block_id=" + block_id;
    form.action = new_form_action_url
  }

  viewMe(event) {
    var editable_part = event.target.closest("[data-target*='step--step.editable']");
    editable_part.classList.add("viewing");
    editable_part.classList.remove("editing");
  }

  viewAll() {
    var editable_parts = this.editableTargets
    for (let editable_part of editable_parts) {
      editable_part.classList.remove("editing");
      editable_part.classList.add("viewing");
    }
    var focused_fields = this.element.querySelectorAll(".focused");
    for (let focused_field of focused_fields) {
      focused_field.classList.remove("focused");
    }
  }

  resetAllConfigurationForms() {
    // Hides/resets all configurations forms
    resetConfigurationForms(this.element);
  }

}


function resetConfigurationForms(step_element) {
  // Hide/reset all configurations forms
  var create_configurations_divs = step_element.querySelectorAll(".create-configurations");
  for (let create_configurations_div of create_configurations_divs) {
    create_configurations_div.classList.add("hidden");
  }
  var add_first_configurations_links = step_element.querySelectorAll(".add-first-configuration");
  for (let add_first_configurations_link of add_first_configurations_links) {
    add_first_configurations_link.classList.remove("hidden");
  }
  var add_configurations_links = step_element.querySelectorAll(".add-item");
  for (let add_configurations_link of add_configurations_links) {
    add_configurations_link.classList.remove("hidden");
  }
  var edit_configurations_options_divs = step_element.querySelectorAll(".configurations-set-options.editing-configurations-options");
  for (let edit_configurations_options_div of edit_configurations_options_divs) {
    edit_configurations_options_div.classList.remove("editing-configurations-options");
    edit_configurations_options_div.classList.remove("viewing-configurations-options");
  }
  var editing_configuration_items = step_element.querySelectorAll(".configurations-list .configuration-item.configuring");
  for (let editing_configuration_item of editing_configuration_items) {
    editing_configuration_item.classList.remove("configuring");
    editing_configuration_item.querySelector(".item-content").classList.remove("hidden");
    var form = editing_configuration_item.querySelector("form");
    form.parentNode.removeChild(form);
  }
}
// Launching a modal onto the page is handled via Rails Ajax server-rendered javascript.
// This controller takes care of closing/hiding modals.

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['modal', 'box']

  clickOutsideHide(event) {
    if (this.boxTarget.contains(event.target) === false && event.target.dataset.enableModal !== "true") {
      this.modalTarget.classList.add("hidden");
    }
  }

  hide() {
    this.modalTarget.classList.add("hidden");
  }

}
import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['content'];

  connect() {
  }

  checkElementPresence() {
    let assignMembers = this.contentTarget.querySelector(".manage-assignments .manage-assignments-list .selected-status");;
    let assignGuests = this.contentTarget.querySelector(".manage-guests .manage-guests-list .selected-status");
    if(assignMembers && assignGuests) {
      event.preventDefault();
    }
  }
  
}
// Launching a modal onto the page is handled via Rails Ajax server-rendered javascript.
// This controller takes care of closing/hiding modals.

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['modal', 'box']

  clickOutsideHide(event) {
    var extra_settings_hidden = this.element.querySelector("#extra-settings.hidden");
    if ((this.boxTarget.contains(event.target) === false) && (extra_settings_hidden !== null)) {
      this.modalTarget.classList.add("hidden");
    }
  }

  hide() {
    this.modalTarget.classList.add("hidden");
  }

  toggle_more_settings() {
    if (this.boxTarget.classList.contains("more-settings")) {
      this.boxTarget.classList.remove("more-settings");
    } else {
      this.boxTarget.classList.add("more-settings");
    }
  }

}
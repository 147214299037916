import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['button']

  change() {
    this.buttonTarget.classList.remove("button-disabled");
  }

}
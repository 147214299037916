import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [ "blockEditor", "preFillEditor",
                      "blockText", "preFillText", 
                      "blockDate", "preFillDate" ];

  connect() {
    if(this.hasBlockEditorTarget && this.hasPreFillEditorTarget) 
    {
      this.blockEditorTarget.addEventListener("trix-change", () => {
        this.preFillEditorTarget.inputElement.value = this.blockEditorTarget.inputElement.value;
      })
    } 
    else if(this.hasBlockTextTarget && this.hasPreFillTextTarget) 
    {
      this.blockTextTarget.addEventListener("keyup", () => {
        this.preFillTextTarget.value = this.blockTextTarget.value;
      })
    } 
    else if(this.hasBlockDateTarget && this.hasPreFillDateTarget) 
    {
      this.blockDateTarget.addEventListener("change", () => {
        this.preFillDateTarget.value = this.blockDateTarget.value;
      })
    }
  }

}
import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['kanbanElement']

  connect() {
    var dragula = require('dragula');

    var kanbanLists = this.element.querySelectorAll(".kanban-list-items");

    this.drake = dragula({
      containers: Array.from(kanbanLists)
    });
    this.drake.on('drop', this.changePosition.bind(this))
  }

  changePosition(el, target, source, sibling) {
    var elementId = this.getElementId(el.id);
    var targetKanbanId = this.getElementId(target.parentNode.id);
    var sourceKanbanId = this.getElementId(source.parentNode.id);
    var siblingId = null;
    // while moving project card with change list option
    // sibling element from dragula is pointing to source kanban list sibling element 
    // instead of returning the sibling after drop so we fetch it manually
    sibling = el.nextElementSibling;
    if(sibling) {
      siblingId = this.getElementId(sibling.id);
    }
    let data = { kanban_list_id: targetKanbanId, source_kanban_list_id: sourceKanbanId, id: elementId, sibling_id: siblingId };
    
    fetch(`/${accountId}/projects/${elementId}/update_kanban_position`,{
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if (!response.ok) {
        throw response
      }
      return response.json();
    }).then((responseJson) => {
      this.changeDeleteOption(responseJson, sourceKanbanId);
      this.changeDropdownLinks(responseJson, el, target);
      if(source !== target) {
        this.changeDropdownLinks(responseJson, el, source);
      }
      this.updateKanbanInOptions(responseJson, el)
    }).catch((error) => {
      console.log(error)
    })
  }
  
  getElementId(elementId) {
    var lastHyphenIndex = elementId.lastIndexOf('-');
    return elementId.substring(lastHyphenIndex + 1);
  }

  changeDeleteOption(responseJson, sourceKanbanId) {
    var kanban_board = document.querySelector(`#kanban-list-${responseJson.kanban_list_id}`);
    var dropdown_delete_wrap = this.getDeleteWrap(kanban_board);
    dropdown_delete_wrap.insertAdjacentHTML("beforeEnd", `<a data-remote="true" data-method="get" 
          href="/${responseJson.account_id}/boards/${responseJson.board_id}/kanban_lists/${responseJson.kanban_list_id}/delete_with_project">
          <span class="icon icon-bin2 icon-left"></span> Delete List</a>`)

    var source_kanban_board = document.querySelector(`#kanban-list-${sourceKanbanId}`);
    var source_kanban_board_item = source_kanban_board.querySelector(`#kanban-list-items-${sourceKanbanId}`);
    if(source_kanban_board_item.querySelector("a.item-link") === null){
      var source_dropdown_delete_wrap = this.getDeleteWrap(source_kanban_board);
      source_dropdown_delete_wrap.insertAdjacentHTML("beforeEnd", `<a data-target="delete.link"
            data-action="ajax:beforeSend->delete#click" data-remote="true" rel="nofollow"
             data-method="delete" href="/${responseJson.account_id}/boards/${responseJson.board_id}/kanban_lists/${sourceKanbanId}">
            <span class="icon icon-bin2 icon-left"></span> Delete List</a>`)
    }
  }
  
  getDeleteWrap(kanban_board) {
    var kanban_setting = kanban_board.querySelector("#kanban-list-settings");
    var dropdown_delete_wrap = kanban_setting.querySelector(".delete-wrap");
    var delete_element = dropdown_delete_wrap.querySelector("a");
    if(delete_element) {
      delete_element.parentNode.removeChild(delete_element);
    }
    return dropdown_delete_wrap
  }

  changeDropdownLinks(responseJson, element, target) {
    let children = target.children;
    for (var i = 0; i < children.length; i++) {
      var childElement = children[i];
      var projectId = this.getElementId(childElement.id);
      
      var dropContent = childElement.querySelector(".item-settings .dropdown-content");
      var sendToTopLink = dropContent.querySelector(".send_to_top");
      var sendToBottomLink = dropContent.querySelector(".send_to_bottom");
      if(sendToTopLink !== null && sendToTopLink !== undefined) {
        dropContent.removeChild(dropContent.querySelector(".send_to_top"));
      }
      if(sendToBottomLink !== null && sendToBottomLink !== undefined) {
        dropContent.removeChild(dropContent.querySelector(".send_to_bottom"));
      }
    
      if(children.length > 1) {
        if(i !== 0)
          this.addSendToTop(responseJson, projectId, childElement);

        if(i !== (children.length - 1))
          this.addSendToBottom(responseJson, projectId, childElement);
      }
    }
  }
  
  addSendToTop(responseJson, projectId, element) {
    let dropdown = element.querySelector(".item-settings .dropdown-content");
    dropdown.insertAdjacentHTML("afterbegin", `<a class="send_to_top" 
        data-action="click->show-hide#toggle click->dropdown#toggle click->kanban#moveToTop" href="javascript:void(0)">
        <span class="icon icon-arrow-up16 icon-left"></span> Send to top
    </a>`);
  }
  
  addSendToBottom(responseJson, projectId, element) {
    let top_option = element.querySelector(".item-settings .dropdown-content .send_to_top");
    if(top_option) {
      top_option.insertAdjacentHTML("afterend", `<a class="send_to_bottom" 
        data-action="click->show-hide#toggle click->dropdown#toggle click->kanban#moveToBottom" href="javascript:void(0)">
        <span class="icon icon-arrow-down16 icon-left"></span> Send to bottom
      </a>`);
    } else {
      let dropdown = element.querySelector(".item-settings .dropdown-content");
      dropdown.insertAdjacentHTML("afterbegin", `<a class="send_to_bottom" 
        data-action="click->show-hide#toggle click->dropdown#toggle click->kanban#moveToBottom" href="javascript:void(0)">
        <span class="icon icon-arrow-down16 icon-left"></span> Send to bottom
      </a>`);
    }
  }

  updateKanbanInOptions(responseJson, el) {
    el.querySelector("#kanban_list_id").innerHTML = '';
    let optionKey = Object.keys(responseJson.kanban_list);
    var optionList = '';
    for(var i = 0; i < optionKey.length; i++) {
      optionList += `<option value="${optionKey[i]}">${responseJson.kanban_list[optionKey[i]]}</option>`;
    }

    el.querySelector("#kanban_list_id").innerHTML = optionList;
  }
  
  dragAndDropProject(event, option) {
    let moveDiv = event.target;
    // Traversing back the project card from "Move to list" btn in dropdown
    while(!moveDiv.classList.contains("index-item")) {
      moveDiv = moveDiv.parentElement;
    }
    
    var projectElement = moveDiv;
    var targetKanban = moveDiv;
    if(option === "changeList") {
      let targetList = moveDiv.querySelector("#kanban_list_id").value;
      targetKanban = document.querySelector(`#kanban-list-items-${targetList}`);
    } else {
      // Traversing back the parent kanban list from project card
      while(!targetKanban.classList.contains("kanban-list-items")) {
        targetKanban = moveDiv.parentElement;
      }      
    }
    
    this.drake.start(projectElement);
    if(option === "moveToBottom" || option === "changeList")
      targetKanban.appendChild(projectElement);
    else
      targetKanban.insertAdjacentElement("afterbegin", projectElement);
    this.drake.end();
  }
  
  changeList(event) {
    this.dragAndDropProject(event, "changeList");
  }
  
  moveToBottom(event) {
    this.dragAndDropProject(event, "moveToBottom");
  }
  
  moveToTop(event) {
    this.dragAndDropProject(event, "moveToTop");
  }

}
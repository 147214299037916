// Create a Dropdown using the following markup:
//
// <div class="dropdown" data-controller="dropdown">
//   <button class="dropdown-toggle" data-action="click->dropdown#toggle click@window->dropdown#hide">Drop me down!</button>
//   <div class="dropdown-content hidden" data-target="dropdown.content" data-action="turbolinks:before-cache@window->dropdown#hide">
//     <%= link_to "Accounts", "/accounts" %>
//     <%= link_to "Home", "/" %>
//   </div>
// </div>

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['content']

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden';
    this.toggleParentClass = this.data.get('class') || 'open';
  }

  toggle() {
    this.contentTarget.classList.toggle(this.toggleClass);
    this.element.classList.toggle(this.toggleParentClass);
  }

  hide(event) {
    if (
      this.element.contains(event.target) === false &&
      !this.contentTarget.classList.contains(this.toggleClass)
    ) {
      this.contentTarget.classList.add(this.toggleClass);
      this.element.classList.toggle(this.toggleParentClass);
    }
  }

}
// This is a project-specific version of last_visited_controller so that we can redirect to the specific task_list if tasks was the last visited tab on project.

import { Controller } from 'stimulus'

export default class extends Controller {

  // The 'single' target's existence is used to distinguish between the index page items and the destination pages.
  static targets = [ "single", "singleTaskList" ]

  connect() {
    if (this.hasSingleTarget) {
      var slug = this.data.get("slug");
      var name = this.data.get("name");
      var base_path_name = this.data.get("base-path-name");
      var base_path = this.data.get("base-path");
      // var task_list = this.data.get("task_list");
      localStorage.setItem(name, slug);
      localStorage.setItem(base_path_name, base_path);

      // If this is a single task list, then also store that task list ID so that when user returns to the Tasks tab it redirects to this task list.
      if (this.hasSingleTaskListTarget) { 
        localStorage.setItem(name+"-task-list", slug); // slug = task_list ID when viewing a task list
      }
    }
  }

  redirectToLastVisited(event) {
    var project_id = this.data.get("project-id");
    var account_id = this.data.get("account-id");
    var last_visited_name = "project-"+project_id+"-last-visited";
    var last_visited_base_path_name = "project-"+project_id+"-last-visited-base-path";
    if (localStorage.getItem(last_visited_name) !== null) {
      event.preventDefault();
      if (localStorage.getItem(last_visited_base_path_name) !== null) {
        var base_path = localStorage.getItem(last_visited_base_path_name);
      } else {
        var base_path = account_id+"/projects/"+project_id;
      }
      var slug = localStorage.getItem(last_visited_name);
      window.location.href = "/"+base_path+"/"+slug;
    }
  }

  redirectToLastVisitedTaskList(event) {
    var project_id = event.target.closest("a").dataset.projectid;
    var account_id = event.target.closest("a").dataset.accountid;
    var last_visited_task_list = localStorage.getItem("project-"+project_id+"-last-visited-task-list");
    if (last_visited_task_list) {
      event.preventDefault();
      window.location.href = "/"+account_id+"/projects/"+project_id+"/task_lists/"+last_visited_task_list;
    }
  }

}

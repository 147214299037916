import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    var something_selected = this.element.querySelector("a.selected");
    if (!something_selected) {
      this.element.classList.add("none-selected");
    }
  }

}

import { Controller } from 'stimulus'

export default class extends Controller {
  
  static targets = ['showHiddenTask', 'content', 'setting', 'dropdown'];

  connect() {
    if(this.contentTarget.classList.contains("hidden") !== true) {
      this.loadGuestOptions();
    }
  }

  loadGuestOptions() {
    this.settingTarget.click();
  }
  
  toggle(event) {
    let showHideOption = this.showHiddenTaskTarget.querySelector("#show-hide-option")
    let taskList = document.querySelector("#page-content.task-list-content .steps");
    let taskListContent = taskList.querySelectorAll("ol > li");
    let showTask = event.target.getAttribute("data-task-list-setting-toggle");
    let taskHiddenInfo;
    
    // Get data attribute from parent element if child element was clicked
    if(showTask === null) {
      showTask = event.target.parentNode.getAttribute("data-task-list-setting-toggle");
    }
    
    // Toggle show/hide hidden tasks in task list 
    for(let taskContent of taskListContent) {
      if (showTask === "0") {
        // Hide tasks that has task hidden info
        taskHiddenInfo = taskContent.querySelector(".task-hidden");
        if (taskHiddenInfo.classList.contains("hidden") === false) {
          taskContent.classList.add("hidden");
        }
      }
      else {
        // Show tasks that are hidden
        if (taskContent.classList.contains("hidden") === true) {
          taskContent.classList.remove("hidden");
        }
      }
    }

    // Toggle Show/Hide hidden tasks text in task list setting
    if(showTask === "1") {
      event.target.setAttribute("data-task-list-setting-toggle", "0");
      showHideOption.innerHTML = "Hide hidden tasks"
    } else {
      event.target.setAttribute("data-task-list-setting-toggle", "1");
      showHideOption.innerHTML = "Show hidden tasks"
    }

    event.preventDefault();
  }
  
  checkElementPresence(event) {
    var taskListGuestContent = this.dropdownTarget.querySelector(".inner-options-list.manage-guests-list .selected-status");
    if(taskListGuestContent) {
      event.preventDefault();
    }
  }
}
import { Controller } from 'stimulus';
import { createPopper } from '@popperjs/core';

export default class extends Controller {

  static targets = ['tourStep']

  connect() {
    // Override the .app-nav z-index because it interferes with the modal background
    document.querySelector(".app-nav.dropdown").style.zIndex = 0;
  }

  show_step_1() {
    const inbound_list = document.querySelector('.kanban-lists .kanban-list:first-child .kanban-list-header');
    const inbound_list_tourstop = document.querySelector('#tour-step-1');
    createPopper(inbound_list, inbound_list_tourstop, {
                  placement: 'bottom-start',
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 35],
                      },
                    },
                  ],
                });
    
    this.hide_all();
    document.querySelector('#tour-step-1').classList.remove("hidden");
    document.querySelector('#tour-step-1').classList.add("slide-up");
  }

  show_step_2() {
    const customize_kanban_list = document.querySelector('.kanban-lists .kanban-list:first-child .kanban-list-header #kanban-list-settings');
    const customize_kanban_list_tourstop = document.querySelector('#tour-step-2');
    createPopper(customize_kanban_list, customize_kanban_list_tourstop, {
                  placement: 'bottom',
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [4, 16],
                      },
                    },
                  ],
                });

    this.hide_all();
    document.querySelector('#tour-step-2').classList.remove("hidden");
  }

  show_step_3() {
    const create_project = document.querySelector('.page-sub-options .page-actions .page-action.button');
    const create_project_tourstop = document.querySelector('#tour-step-3');
    createPopper(create_project, create_project_tourstop, {
                  placement: 'left',
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [2, 35],
                      },
                    },
                  ],
                });

    this.hide_all();
    document.querySelector('#tour-step-3').classList.remove("hidden");
  }

  start() {
    var modal = document.querySelector(".modal#welcome-to-tour");
    modal.parentNode.removeChild(modal);
  }

  finish() {
    this.hide_all();
  }

  hide_all() {
    for (let tourstep of this.tourStepTargets) {
      tourstep.classList.add("hidden");
    }
    var forced_show_elements = document.querySelectorAll(".tour-force-show");
    for (let element of forced_show_elements) {
      element.classList.remove("tour-force-show");
    }
    // Set the .app-nav z-index back to its normal value of 10000
    document.querySelector(".app-nav.dropdown").style.zIndex = 10000;
  }

}
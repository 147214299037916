// <%= form_with(model: ..., url: ..., data: { controller: "select-field-options" }) do |f| %>
  
//   <div class="field select">
//     <%= f.label :field_name %>
    // <%= f.select :field_name, 
    //              options_for_select([["Visible Option Text A", "value-a"], ["Visible Option Text B", "value-b", ["Visible Option Text C", "value-c"]]]), 
                 // {}, 
                 // { data: {
                 //    "target": "select-field-options.select", 
                 //    "action": "change->select-field-options#triggerChange", 
                 //    "trigger-if-selected": "['value-b', 'value-c']"
                 //   }
    //              } %>
//     <span class="icon icon-arrow-down3"></span>
//   </div><!--/ .field.select -->

  // <div class="field hidden" 
  //      data-target="select-field-options.showHideMe">
  //      Shown if a trigger options are selected.
  //      Hidden if a non-trigger option is selected.
  // </div><!--/ .field.field-template-options -->

// <% end %>

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['select', 'showHideMe', 'defaultValue']
  
  connect() {
    var showModal;
    var instanceCount;
    this.triggerChange();
  }

  triggerChange() {
    var change_if_selected = this.selectTarget.dataset.triggerIfSelected;
    var hide_both = this.selectTarget.dataset.hideBoth;
    var selected_option = this.selectTarget.value;
    if (change_if_selected.includes(selected_option)) {
      if(this.hasDefaultValueTarget) {
        this.defaultValueTarget.classList.add("hidden");
      }
      this.showHideMeTarget.classList.remove("hidden");
    } else if (hide_both && hide_both.includes(selected_option)) {
      if(this.hasDefaultValueTarget) {
        this.defaultValueTarget.classList.add("hidden");
      }
      this.showHideMeTarget.classList.add("hidden");
    } else{
      if(this.hasDefaultValueTarget) {
        this.defaultValueTarget.classList.remove("hidden");
      }
      this.showHideMeTarget.classList.add("hidden");
    }
  }

  // Contains code that is specific to client attributes edit forms
  warnChange() {
    var selected_option = this.selectTarget.value;
    var eventTarget = this.selectTarget.parentElement;
    while(eventTarget.tagName !== "FORM") {
      eventTarget = eventTarget.parentElement;
    }
    let find_form = eventTarget.action
    var lastSlashIndex = find_form.lastIndexOf('/');
    var templateId = find_form.substring(lastSlashIndex + 1);
    
    if(typeof(templateId) != 'undefined' && templateId != null && !isNaN(templateId)){
      let data = { selected_option: selected_option };
  
      fetch(`/${accountId}/client_attributes/${templateId}/change_in_instances`,{
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        return response.json();
      }).then((json) => {
        this.showModal = json.show_modal
        this.instanceCount = json.template_instances
        let spanCount = document.getElementById(`instance_count_${templateId}`);
        let clientLabel = document.getElementById(`client_label_${templateId}`);
        let modalSubmit = document.getElementById(`template_submit_${templateId}`);
        let updateSubmit = document.getElementById(`update_button_${templateId}`);
        spanCount.innerHTML = this.instanceCount
        clientLabel.innerHTML = json.client_label
        modalSubmit.value += `${json.template_instances} ${json.client_button}`
        if(typeof(this.showModal) != 'undefined' && this.showModal != null && this.showModal === true && this.instanceCount > 0) {
          updateSubmit.type = 'button'
        } else {
          updateSubmit.type = 'submit'
        }
      }).catch((error) => {
        console.log(error)
      })
    }
  }

  preventUpdate() {
    if(typeof(this.showModal) != 'undefined' && this.showModal != null && this.showModal === true && this.instanceCount > 0) {
      var eventTarget = this.selectTarget.parentElement;
      while(eventTarget.tagName !== "FORM") {
        eventTarget = eventTarget.parentElement;
      }
      let findForm = eventTarget.action
      var lastSlashIndex = findForm.lastIndexOf('/');
      var templateId = findForm.substring(lastSlashIndex + 1);
      let updateSubmit = document.getElementById(`update_button_${templateId}`);
      if(updateSubmit.type === "button" && !isNaN(templateId)) {
        document.getElementById(`change_in_instance_${templateId}`).classList.remove('hidden');
      }
    }
  }

}
// This is a version of tabs_controller.js that was copied into /tasks so that we can add more specific functionality to it that only applies to tasks' tabs.

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "tab", "tabPanel" ]

  connect() {
    var tabs_id = this.data.get("tabs-id");
    var url = window.location.href;
    if (url.indexOf("comment=true") > -1) {
      // var task_id = this.data.get("task-id");
      var task_id = url.split('#task_').pop();
      var step = document.querySelector("#task_"+task_id);

      // set the tab to 'activity'
      this.data.set("active-tab-id", task_id+"_activity");
      localStorage.setItem(tabs_id, task_id+"_activity");

      // ensure this task is expanded
      if (step.classList.contains("minimized")) {
        step.classList.remove("minimized");
        step.classList.add("expanded");
        step.querySelector(".step").classList.remove("minimized");
        step.querySelector(".step").classList.add("expanded");
      }

    } else {
      if (localStorage.getItem(tabs_id) != null) {
        this.data.set("active-tab-id", localStorage.getItem(tabs_id));
      }
    }

    var active_tab_id = this.data.get("active-tab-id");
    if (this.hasTabTarget) {
      var active_tabs = this.element.querySelectorAll(".tab[data-tabid='"+active_tab_id+"']");
    }
    if (this.hasTabPanelTarget) {
      var active_panels = this.element.querySelectorAll(".tab-panel[data-panelid='"+active_tab_id+"']");
    }
    
    if (active_tabs != null) {
      for (let active_tab of active_tabs) {
        active_tab.classList.add("active");
      }
      for (let active_panel of active_panels) {
        active_panel.classList.add("active");
      }
    } else {
      var first_found_tab = this.element.querySelector(".step-content-panels .tab-panel");
      if (first_found_tab != null) {
        first_found_tab.classList.add("active");
        if (this.hasTabTarget) {
          this.element.querySelector(".tab[data-tabid='"+first_found_tab.dataset.panelid+"']").classList.add("active");
        }
      }
    }
    
  }

  activateTab(event) {

    var tabs_id = this.data.get("tabs-id");
    var active_tab_id = this.data.get("active-tab-id");
    var tabs = this.tabTargets
    var tab_id = event.target.closest(".tab").dataset.tabid;
    var panels = this.tabPanelTargets

    for (let tab of tabs) {
      if (tab.dataset.tabid == tab_id) {
        tab.classList.add("active");
        this.data.set("active-tab-id", tab_id);
        localStorage.setItem(tabs_id, tab_id);
      } else {
        tab.classList.remove("active");
      }
    }

    for (let panel of panels) {
      if (panel.dataset.panelid === tab_id || panel.dataset.panelid === event.target.closest(".tab").dataset.tabid ) { 
        panel.classList.add("active");
      } else {
        panel.classList.remove("active");
      }
    }

  }

}
import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['linkText']

  copy() {
    var copy_content = this.data.get("content");
    var success_message = this.data.get("success-message");

    var input = document.createElement('input');
    input.setAttribute('value', copy_content);
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);

    if (success_message) {
      var initial_text = this.linkTextTarget.innerHTML
      this.linkTextTarget.innerHTML = success_message;
      
      this.timeout = setTimeout(() => {
        this.linkTextTarget.innerHTML = initial_text;
      }, 3000);
      // To prevent adding form-focused class in form for form editing
      event.stopImmediatePropagation()
      event.preventDefault()
    }
    this.element.classList.add("copied");

    this.timeout = setTimeout(() => {
      this.element.classList.remove("copied");
    }, 3000);
  }
  
}
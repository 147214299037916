import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['input', 'copyButton']

  connect() {
  }

  highlight() {
    this.inputTarget.focus();
    this.inputTarget.select();
    this.copyButtonTarget.classList.remove("hidden");
    if (this.copyButtonTarget.classList.contains("button-secondary")) {
      this.copyButtonTarget.classList.remove("button-secondary");
      this.copyButtonTarget.innerHTML = "Copy"
    }
  }

  copy() {
    document.execCommand("Copy");
    if (this.hasButtonTarget) {
      this.copyButtonTarget.classList.add("button-secondary");
      this.copyButtonTarget.innerHTML = "Copied";
    }
  }

  defocus(event) {
    if (this.element.contains(event.target) === false) {
      if (this.hasButtonTarget) {
        this.copyButtonTarget.classList.add("hidden");
      }
    }
  }
  

}
import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [ "toggler", "cancellableElement" ]

  cancel() {
    this.cancellableElementTarget.parentNode.removeChild(this.cancellableElementTarget);
  }

  showToggler() {
    if (this.hasTogglerTarget) {
      this.togglerTarget.classList.remove("hidden");
    }
  }

}
import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['comment', 'form', 'actions']

  showForm() {
    this.commentTarget.classList.add("hidden");
    this.actionsTarget.classList.add("hidden");
    this.formTarget.classList.remove("hidden");
    var commentForm = this.formTarget.querySelector("trix-editor");
    commentForm.click();
    commentForm.focus();
  }

  hideForm() {
    this.commentTarget.classList.remove("hidden");
    this.actionsTarget.classList.remove("hidden");
    this.formTarget.classList.add("hidden");
  }

}
import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [ "selected", "field" ]

  connect() {
  }

  select(event) {
    var icon_name = event.target.closest("a").dataset.icon;
    var selected_icon = this.selectedTarget.querySelector("span.icon");
    var field = this.fieldTarget;

    selected_icon.className = '';
    selected_icon.classList.add("icon");
    selected_icon.classList.add("icon-"+icon_name);
    field.value = icon_name;
  }

}
// This controller only handles the visual switching of checked and unchecked states, so that this is seen by the user as fast as possible.
// Other stuff that can happen slower is handled by the ajax action javascript (complete.js and uncomplete.js)

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['complete_link', 'uncomplete_link']

  complete(event) {
    event.target.closest('.checkbox-wrap').classList.add('hidden');
    var step = event.target.closest('.step');
    if (step) {
      step.classList.add('complete');
    }
    this.uncomplete_linkTarget.closest('.checkbox-wrap').classList.remove('hidden');
  }

  uncomplete(event) {
    event.target.closest('.checkbox-wrap').classList.add('hidden');
    var step = event.target.closest('.step');
    if (step) {
      step.classList.remove('complete');
    }
    this.complete_linkTarget.closest('.checkbox-wrap').classList.remove('hidden');
  }

}
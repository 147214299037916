import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['step', 'panel', 'input', 'process_title_placeholder']

  connect() {
  }

  proceed(event) {
    var steps = this.stepTargets;
    var proceed_to_step = event.target.dataset.proceedTo;
    if (!proceed_to_step) {
      // since proceed_to_step isn't defined yet, we'll assume user clicked a step in the steps nav
      proceed_to_step = event.target.closest(".builder-step").dataset.proceedTo;
      // User should only be able to navigate to a step if it has already been completed
      if (!event.target.closest(".builder-step").classList.contains("completed")) {
        return;
      }
    }

    var completed = event.target.dataset.completed;

    if (completed == "process") {
      var process_title_field = document.querySelector("input#process_title");
      var builder_field = process_title_field.closest(".builder-field");
      // check if the process field has a value
      if (process_title_field.value) {
        builder_field.classList.remove("error");
        this.mark_completed(steps, completed);
        this.go_to_step(event, steps, this.panelTargets, proceed_to_step);
      } else { // the process_title is blank
        builder_field.classList.add("error");
        process_title_field.focus();
      } 
    } else if (completed == "board") {
      event.preventDefault();
      var board_title_field = document.querySelector("input#board_title");
      var builder_field = board_title_field.closest(".builder-field");
      // check if the board field has a value
      if (board_title_field.value) {
        builder_field.classList.remove("error");
        event.target.closest("form").submit();
      } else { // the process_title is blank
        builder_field.classList.add("error");
        board_title_field.focus();
      } 
    } else {
      this.mark_completed(steps, completed);
      this.go_to_step(event, steps, this.panelTargets, proceed_to_step);
    }
      
  }

  go_to_step(event, steps, panels, proceed_to_step) {

    // Activate the step we're proceeding to
    for (let step of steps) {
      if (step.dataset.step == proceed_to_step) {
        step.classList.add("active");
        step.classList.remove("completed");
      } else {
        step.classList.remove("active");
      }
    }

    // Activate the panel we're proceeding to
    for (let panel of panels) {
      if (panel.dataset.step == proceed_to_step) {
        panel.classList.remove("hidden");
        panel.querySelector(".builder-field input:first-child").focus();
      } else {
        panel.classList.add("hidden");
      }
    }
  }

  mark_completed(steps, completed) {
    var completed_step = steps.find(el => el.dataset.step == completed);
    if (completed_step) {
      completed_step.classList.add("completed");
    }
  }

  reveal_note(event) {
    var hidden = event.target.closest(".builder-field").querySelector(".builder-field-actions .note .hidden");
    if (hidden) {
      hidden.classList.remove("hidden");
    }
  }

  fill_field(event) {
    var fill_value = event.target.dataset.value;
    var field_id = event.target.dataset.fieldToFill;
    var field = document.querySelector("input#"+field_id);
    field.value = fill_value;

    // If we're filling the process title field, then also update the process title placeholders elsewhere
    if (field_id == "process_title") {
      for (let span of this.process_title_placeholderTargets) {
        span.innerHTML = fill_value;
        span.classList.add("process-title");
      }
    }
  }

  // When user fills the process title field, the placeholders that display that process title get updated.
  fill_process_title(event) {
    var process_title = event.target.value;
    if (process_title) {
      for (let span of this.process_title_placeholderTargets) {
        span.innerHTML = process_title;
        span.classList.add("process-title");
      }
    }
  }

}
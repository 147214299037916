import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['form', 'actions', 'trixToolBar']

  showActions() {
    if (this.hasActionsTarget) {
      this.formTarget.classList.add("form-focused");
    } else {
      // if this focuseable form doesn't have an .actions div, 
      // then we assume this is a nested form.  Find the parent form,
      // then add .form-focused to it so that the parent form .actions is shown.
      this.element.closest('form').classList.add("form-focused");
    }
  }

  hideActions() {
    this.formTarget.classList.remove("form-focused");
  }

}
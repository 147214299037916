// This is used for Step/Task Content Blocks and Configurations (Configurations, visibility rules, dependency rules)

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['openCreateConfigurations', 'createConfigurations', 'configurationTypes', 'createConfigurationForm', 'configurationsOptions']

  changeType() {
    this.createConfigurationFormTarget.innerHTML = "";
    this.configurationTypesTarget.classList.remove("hidden");
  }

  cancel(event) {
    // Close and reset the new Configuration box
    this.createConfigurationFormTarget.innerHTML = "";
    this.configurationTypesTarget.classList.remove("hidden");
    this.createConfigurationsTarget.classList.add("hidden");
    this.openCreateConfigurationsTarget.classList.remove("hidden");

    // if cancelling an edit form on an existing step Configuration, reset that
    var step_configuration = event.target.closest('.configuring');
    if (step_configuration) {
      var form = step_configuration.querySelector('form');
      if (form) {
        form.parentNode.removeChild(form);
      }
      step_configuration.querySelector('.item-content, .block-content').classList.remove("hidden");
      step_configuration.classList.remove("configuring");
    }
    var add_item_links = this.element.querySelectorAll(".add-item");
    for (let add_item_link of add_item_links) {
      add_item_link.classList.remove("hidden");
    }
  }

  editConfigurationsOptions(event){
    var configurations_options = event.target.closest("[data-target*='step--step-configurations.configurationsOptions']");
    configurations_options.classList.add("editing-configurations-options");
    configurations_options.classList.remove("viewing-configurations-options");
  }
  viewConfigurationsOptions(event){
    var configurations_options = event.target.closest("[data-target*='step--step-configurations.configurationsOptions']");
    configurations_options.classList.remove("editing-configurations-options");
    configurations_options.classList.add("viewing-configurations-options");
  }

}
import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['content', 'innerContent']

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden';
  }

  toggle() {
    this.contentTarget.classList.toggle(this.toggleClass)
  }

  clickOutsideHide(event) {
    if (
      this.element.contains(event.target) === false &&
      !this.contentTarget.classList.contains(this.toggleClass)
    ) {
      this.contentTarget.classList.add(this.toggleClass);
      this.innerContentTarget.classList.add("hidden");
    }
  }

  hide() {
    this.contentTarget.classList.add(this.toggleClass);
    this.innerContentTarget.classList.add("hidden");
  }

}
// This is our general-use controller for drag-and-drop reordering of elements.
// Note:  This is NOT the controller used for kanban board drag-and-drop.  That is in kanban_controller.js

// <div data-controller="drag-and-drop">
//   <div data-target="drag-and-drop.dragContainer">
//     I am a container that things can be dragged into.
//     <div>
//       I am a draggable thing.
//     </div>
//     <div>
//       I am a draggable thing.
//     </div>
//     <div class="no-drag">
//       I CANNOT be dragged
//     </div>
//   </div>
//   <div data-target="drag-and-drop.dragContainer">
//     I am a container that things can be dragged into.
//     <div>
//       I am a draggable thing.
//     </div>
//     <div>
//       I am a draggable thing.
//     </div>
//   </div>
// </div>

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['dragContainer']

  connect() {
    var dragula = require('dragula');
    this.drake = dragula({
      containers: Array.from(this.dragContainerTargets),
      invalid: (el, handle) => el.classList.contains('no-drag')
    });
  } 

}
import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['richTextContent', 'linkText']

  connect() {
  }

  copy(event) {

    this.richTextContentTarget.style.setProperty('background', '#ffffff');
    this.richTextContentTarget.style.setProperty('font-family', 'arial, sans-serif');
    this.richTextContentTarget.style.setProperty('font-size', 'initial');
    this.richTextContentTarget.style.setProperty('color', 'initial');

    var range = document.createRange();
    range.selectNode(this.richTextContentTarget);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');

    this.richTextContentTarget.style.setProperty('background', 'inherit');
    this.richTextContentTarget.style.setProperty('font-family', 'inherit');
    this.richTextContentTarget.style.setProperty('font-size', 'inherit');
    this.richTextContentTarget.style.setProperty('color', 'inherit');

    var success_message = this.data.get("success-message");
    if (success_message) {
      var initial_text = this.linkTextTarget.innerHTML
      this.linkTextTarget.innerHTML = success_message;
      
      this.timeout = setTimeout(() => {
        this.linkTextTarget.innerHTML = initial_text;
      }, 3000);
      // To prevent adding form-focused class in form for form editing
      event.stopImmediatePropagation()
      event.preventDefault()
    }
    this.element.classList.add("copied");

    this.timeout = setTimeout(() => {
      this.element.classList.remove("copied");
    }, 3000);
  }
  
}
import consumer from "./consumer"

document.addEventListener('turbolinks:load', function() {
  let taskList = document.querySelector("#page-content.task-list-content");
  if (taskList) {
    consumer.subscriptions.create({channel: "DependentTaskChannel", task_list_id: taskList.getAttribute("data-task-list-id")}, {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        var task_li = document.querySelector(`#task_list_${data.task.task_list_id} ol.steps li#task_${data.task.id}`);
        var task_step = task_li.querySelector(".step")
        var task_expanded = task_step.classList.contains("expanded")
        var params = new URLSearchParams({ task_expanded: task_expanded })
        // Ajax call to get updated task partial html content
        fetch(`/${data.task.account_id}/projects/${data.task.project_id}/task_lists/${data.task.task_list_id}/tasks/${data.task.id}/socket_data?${params}`,
            {
              method: "GET",
              headers: {
                'Content-Type': 'application/json'
              }
            }
        ).then((response) => {
          return response.json();
        }).then((json) => {
          if(task_li) {
            var task_step_toggler = task_li.querySelector(`step-connector-${data.task.id}`);
            
            // Updating task element with updated html content 
            task_li.innerHTML = json.task;
            
            // Show/hide task based on task visibility
            if(json.hidden === false){
              task_li.classList.remove("hidden");
              if(task_step_toggler === undefined || task_step_toggler === null)
                task_li.insertAdjacentHTML("beforeend", json.task_connector);
            }
            else {
              task_li.classList.add("hidden");
            }
            
            this.show_hide_task(data)
          }
        });
      },

      // Show/hide show hidden tasks element in task list settings
      show_hide_task(data) {
        let task_list_content = taskList.querySelectorAll("ol > li");
        let hidden_task_exist = false;

        // Checks if there is any hidden task in task list 
        for(let task_content of task_list_content) {
          if (task_content.classList.contains("hidden")) {
            hidden_task_exist = true;
            break;
          }
        }

        // Show/hide show hidden tasks element in settings based on the `hidden_task_exist` value
        let task_hide_link = document.querySelector(`#show_hidden_task_${data.task.task_list_id}`);
        if (hidden_task_exist && task_hide_link)
          task_hide_link.classList.remove("hidden");
        else if (task_hide_link)
          task_hide_link.classList.add("hidden");
      }
    });
  }
});

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['container', 'truncated']

  initialize() {

    if (this.containerTarget.scrollHeight < 200) {
      this.containerTarget.classList.add('no-truncation');
    }
    
  }

  connect() {
    this.toggleContainerClass = this.data.get('class') || 'open';

    var truncate_textarea_id = this.data.get("truncate-textarea-id");
    
    if (localStorage.getItem(truncate_textarea_id) === "open") {
      this.containerTarget.classList.toggle(this.toggleContainerClass);
    }
  }

  open() {
    this.containerTarget.classList.add("open");
  }

  toggle() {
    this.containerTarget.classList.toggle(this.toggleContainerClass);

    var truncate_textarea_id = this.data.get("truncate-textarea-id");

    if (localStorage.getItem(truncate_textarea_id) === null) {
      localStorage.setItem(truncate_textarea_id, "open");
    } else {
      if (localStorage.getItem(truncate_textarea_id) == 'open') {
        localStorage.setItem(truncate_textarea_id, "closed");
      } else {
        localStorage.setItem(truncate_textarea_id, "open");
      }
    }
    
  }

}
// Used for handling "delete" / "really delete?" on links that use ajax and will not load a new page.  If the link is NOT ajax, then use delete_static_controller.js instead.

const RESET_TIMEOUT_MILLIS = 3000;
const CONFIRMATION_MESSAGE = 'Really delete?';

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['link']

  connect() {
    this.delete = false;
  }

  click(event) {
    let preventRemove = this.element.getAttribute("data-prevent-remove");
    if(this.delete && preventRemove) {
      this.resetState();
    } else if (this.delete && (preventRemove === undefined || preventRemove === null)) {
      this.element.style = 'display: none;';
      this.linkTarget.addEventListener('ajax:success', this.handleSuccess.bind(this))
      this.linkTarget.addEventListener('ajax:error', this.handleError.bind(this))
    } else {
      this.oldMessage = this.linkTarget.innerHTML;
      var custom_confirmation_message = this.data.get("confirmation-message");
      if (custom_confirmation_message) {
        this.linkTarget.innerHTML = custom_confirmation_message
      } else {
        this.linkTarget.innerHTML = CONFIRMATION_MESSAGE;
      }
      this.delete = true;
      this.timeout = setTimeout(() => {
        this.resetState();
      }, RESET_TIMEOUT_MILLIS);
      event.preventDefault();
      return false;
    }
  }

  handleSuccess(event) {
    clearTimeout(this.timeout);
    if (this.element.parentNode) {
      this.element.parentNode.removeChild(this.element);
    }
  }

  handleError(event) {
    clearTimeout(this.timeout);
    this.resetState();
    this.element.style = '';
  }

  resetState() {
    if (this.delete) {
      this.linkTarget.removeEventListener('ajax:success', this.handleSuccess.bind(this))
      this.linkTarget.removeEventListener('ajax:error', this.handleError.bind(this))
      this.linkTarget.innerHTML = this.oldMessage;
      this.delete = false;
    }
  }
}
import { Controller } from 'stimulus'

export default class extends Controller {

  hide() {
    var recalculate_button = this.element.querySelector('.date-rule-action.recalculate');
    if (recalculate_button) {
      recalculate_button.classList.add("hidden");
    }
  }

}
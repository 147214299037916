// Used only for forms that need to be swapped out with static elements on click, like the Project Title.

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['viewContent', 'editContent']

  showEdit() {
    this.viewContentTarget.classList.add("hidden");
    this.editContentTarget.classList.remove("hidden");
    this.editContentTarget.querySelector(".field input").focus();
    this.element.classList.add("editing");
  }

  showView() {
    this.viewContentTarget.classList.remove("hidden");
    this.editContentTarget.classList.add("hidden");
    this.element.classList.remove("editing");
  }

  defocusShowView(event) {
    if (this.element.contains(event.target) === false) {
      this.viewContentTarget.classList.remove("hidden");
      this.editContentTarget.classList.add("hidden");
    }
  }

}